import React from "react"
import styled from "styled-components"
import colors from "../../Colors"
import AppRouteTitle from "../../ReusableComponents/AppRouteTitle"
import AppRouteSubTitle from "../../ReusableComponents/AppRouteSubTitle"

import schoolImage from "./Assets/banner5.jpg"
import lowerSchool1 from "./Assets/LowerSchool_2.jpg"
import lowerSchool2 from "./Assets/LowerSchool_4.jpg"
import MiddleSchool1 from "./Assets/MiddleSchool_2.jpg"
import MiddleSchool2 from "./Assets/MiddleSchool_3.jpg"

import ConductList from "./ConductList"
import ImageGallery from "./ImageGallery"

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin 30px auto;

`

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
`

const Image = styled.img`
  width: 100%;
  height: 70vh;
  object-fit: cover;
`
const ContentContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin 20px auto;

`
const Paragraph = styled.p`
  font-size: 1.1rem;
  color: ${colors.mediumDark};
  font-weight: 500;
`
const Cards = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  margin: 30px auto;
`
const CardDiv = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
`
const CardTitle = styled.p`
  font-size: 1.1rem;
  color: ${colors.mediumDark};
  font-weight: 600;
  margin-top: 10px;
`
const CardDescription = styled.p`
  font-size: 0.9rem;
  color: ${colors.lightGrey};
  font-weight: 400;
`
const SchoolImageContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  position: relative;
`
const OverlayText = styled.p`
  width: 50%;
  height: 400px;
  background-color: rgba(255, 255, 255, 0.7);
  color: ${colors.orange};
  font-size: 2rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
  padding: 20px;
  text-align: center;
  opacity: 0;
  transition-property: opacity, left, top, height;
  transition-duration: 1s, 1s;
  &:hover {
    opacity: 1;
  }
`

const OverlayText2 = styled.p`
  width: 50%;
  height: 400px;
  background-color: rgba(255, 255, 255, 0.7);
  color: ${colors.orange};
  font-size: 2rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 2;
  margin-bottom: 0px;
  margin-right: -20px;
  text-align: center;
  opacity: 0;
  transition-property: opacity, left, top, height;
  transition-duration: 1s, 1s;
  &:hover {
    opacity: 1;
  }
`
const SchoolImages = styled.img`
  width: 50%;
  height: 400px;
  object-fit: cover;
  object-position: center;
  position: relative;
`

const CardContainer = ({ item }) => {
  return (
    <CardDiv>
      {item.icon}
      <CardTitle>{item.title}</CardTitle>
      <CardDescription>{item.description}</CardDescription>
    </CardDiv>
  )
}

export { CardContainer }

function JslSchool() {
  return (
    <Container>
      <ImageContainer>
        <Image src={schoolImage} alt="classroom" />
      </ImageContainer>
      <ContentContainer>
        <AppRouteTitle titleText="JSL School" />
        <AppRouteSubTitle titleText="Curriculum" />
        <Paragraph>
          Jagadeeswari School of Learning & Child Development Center - SETHU
          (A/C Campus), with 3C(PYP) and CBSE Curriculum, highly efficient
          facilitators, under the Management, Observation of Child-psychologist.
        </Paragraph>
        <AppRouteSubTitle titleText="5 C's of Conduct" />
        <Cards>
          {ConductList.map((item) => (
            <CardContainer item={item} />
          ))}
        </Cards>
        <Paragraph>
          Jagadeeswari School of Learning, with an inclusive setup and Therapies
          for Students of DiverseLearning Needs.
        </Paragraph>
        <Paragraph>
          Inclusive Education (IE) is a new approach towards educating the
          children with disability and learning difficulties with that of normal
          ones within the same roof. It brings all students together in one
          classroom and community, regardless of their strengths or weaknesses
          in anyarea, and seeks to maximize the potential of all students. It is
          one of the most effective ways in which to promote an inclusive and
          tolerant society.
        </Paragraph>
        <AppRouteSubTitle titleText="Lower School" />
        <Paragraph>
          Includes - Playgroup, Nursery, Lower Kindergarten (LKG), Upper
          Kindergarten (UKG)
        </Paragraph>
        <Paragraph>
          Students arrive each morning brimming with curiosity and enthusiasm.
          Every day at School is an adventure waiting to happen. The stimulating
          environment, combined with a warm and supportive community, helps
          children learn about themselves in deep and meaningful ways. Our
          School believes in educating the whole child for the whole world, and
          affective skills are an important part of that mission. Weekly classes
          in life skills teach students how to collaborate, resolve conflicts,
          respect divergent perspectives and opinions, and contribute to their
          learning community.
        </Paragraph>
        <Paragraph>
          Amenities -- Ambient temperature rooms, Child friendly infrastructure
          (classrooms, toilets, dining), Child safe fixtures
        </Paragraph>
        <SchoolImageContainer>
          <SchoolImages src={lowerSchool1} alt="children-img" />
          <OverlayText>
            KG interactive game / cognitive and motor skills
          </OverlayText>
          <SchoolImages src={lowerSchool2} alt="children-img" />
          <OverlayText2>
            KG Classroom -- participating in competition
          </OverlayText2>
        </SchoolImageContainer>
        <AppRouteSubTitle titleText="Middle School" />
        <Paragraph>
          Children are oriented to mapping theoretical concepts to real-life
          situations and scenarios
        </Paragraph>
        <Paragraph>
          Curriculum places emphasis on sensitising children to be caring and
          compassionate
        </Paragraph>
        <Paragraph>
          Leverage interactive workshops, seminars, presentations, group
          discussions, field trips to reinforce concepts and encourage
          self-discovery
        </Paragraph>
        <Paragraph>
          Extensive programmes involving music, dance, drama, skits, art, craft
          to pique curiosity, creativity out of the box thinking
        </Paragraph>
        <Paragraph>
          Students are encouraged to participate in extra-curricular activities
          like spell-bee, roleplays, environmental awareness, social
          responsibility events, declamation, debates and story telling.
        </Paragraph>
        <SchoolImageContainer>
          <SchoolImages src={MiddleSchool1} alt="children-img" />
          <OverlayText>Classroom Activity</OverlayText>
          <SchoolImages src={MiddleSchool2} alt="children-img" />
          <OverlayText2>Classroom Session</OverlayText2>
        </SchoolImageContainer>
        <div>
          <AppRouteTitle titleText="All Round Education" />
          <AppRouteSubTitle titleText="Indoor Activities" />
          <ImageGallery />
          <AppRouteSubTitle titleText="Outdoor Activities" />
          <ImageGallery />
        </div>
      </ContentContainer>
    </Container>
  )
}

export default JslSchool
