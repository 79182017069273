import React from "react"
import colors from "../../Colors"
import styled from "styled-components"
import AppTitleCentered from "../../ReusableComponents/AppTitleCentered"
import CourseCard from "./CourseCard"

import kindergarden from "./Assets/LowerSchool_3.jpg"
import middle from "./Assets/MiddleSchool_1.jpg"
import higer from "./Assets/MiddleSchool_3.jpg"
import CBSE1 from "./Assets/Screenshot_14.jpg"
import CBSE2 from "./Assets/PersonalHygiene.jpg"

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: ${colors.lightOrange};
  padding: 30px;
`

const ImageContainer = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  overflow-x: scroll;
  margin: 30px;
`

function BestCourses() {
  return (
    <Container id="COURSES">
      <AppTitleCentered title="Featured Courses" subTitle="Courses We Offer" />
      <ImageContainer>
        <CourseCard
          image={kindergarden}
          title="Kindergarden"
          subTitle="includes nursery, LKG, UKG"
        />
        <CourseCard
          image={middle}
          title="Middle School"
          subTitle="includes 1st to 5th Standard"
        />
        <CourseCard
          image={higer}
          title="Higher School"
          subTitle="includes 6th to 10th Standard"
        />
        <CourseCard
          image={CBSE1}
          title="CBSE Curriculum"
          subTitle="includes nursery, LKG, UKG"
        />
        <CourseCard
          image={CBSE2}
          title="CBSE Curriculum"
          subTitle="includes 1st to 10th Standard"
        />
      </ImageContainer>
    </Container>
  )
}

export default BestCourses
