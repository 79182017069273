import React from "react"

import styled from "styled-components"
import colors from "../../Colors"
import AppButtonFill from "../../ReusableComponents/AppButtonFill"
import AppOrangeRing from "../../ReusableComponents/AppOrangeRing"

import AppTitle from "../../ReusableComponents/AppTitle"
import studentsImage from "./Assets/studentsImage.jpg"

const MainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin: 30px;
  position: relative;
`
const StudentsImage = styled.img`
  width: 400px;
  height: 400px;
  object-fit: cover;
  border-radius: 500px;
  border: 2px solid ${colors.mediumOrange};
`
const ImageContainer = styled.div`
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
`
const ContentContainer = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
`
const AboutSchool = styled.p`
  color: ${colors.lightGrey};
  font-weight: 500;
  line-height: 1.3rem;
  margin: 10px;
`

function Aboutus() {
  return (
    <MainContainer id="ABOUTUS">
      <AppOrangeRing
        width={"50px"}
        height={"50px"}
        radius={"25px"}
        bottom={"50px"}
        left={"50px"}
        borderWidth={"10px"}
      />
      <AppOrangeRing
        width={"100px"}
        height={"100px"}
        radius={"50px"}
        top={"80px"}
        right={"10px"}
        borderWidth={"15px"}
      />
      <ImageContainer>
        <StudentsImage src={studentsImage} alt="students image" />
      </ImageContainer>
      <ContentContainer>
        <AppTitle
          title="About Us"
          subTitle="Welcome to Jagadeeshwari School of Learning & Child Developement Center"
        />
        <AboutSchool>
          We believe that thinking should be “out of the box”, not “tick the
          box” and our infectious passion for learning means we achieve more
          than outstanding results: we build confident, creative thinkers and
          deliver an education that is truly relevant to their future. We are
          part of JSL (Jagadeeswari School of Learning) wherein we recognize
          that parents are the first educators of their children. Through a
          system of education which is personalized, we seek to integrate the
          pursuit of academic excellence, the acquisition of skills and the
          development of the student’s character.
        </AboutSchool>
        <div style={{ margin: "10px" }}>
          <AppButtonFill buttonText="Know More" width="120px" />
        </div>
      </ContentContainer>
    </MainContainer>
  )
}

export default Aboutus
