import React from "react"
import styled from "styled-components"
import colors from "../../Colors"
import AppinputWithLabel from "../../ReusableComponents/AppinputWithLabel"

import AppButtonFill from "../../ReusableComponents/AppButtonFill"

const Container = styled.div`
  width: 100%;
  margin: 0px auto;
  margin-top: 30px;
  padding: 30px;
`

const InnerContainer = styled.div`
  width: 80%;
  margin: 20px auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 20px;
`
const TextArea = styled.div`
  width: 80%;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`
const Label = styled.label`
  margin: 5px;
  font-size: 1.1rem;
  color: ${colors.mediumDark};
  font-weight: 500;
  text-align: left;
`

const Instruction = styled.label`
  margin: 20px 5px;
  font-size: 1.2rem;
  color: ${colors.mediumDark};
  font-weight: 500;
  text-align: left;
`

const InputBox = styled.textarea`
  width: 100%;
  height: 200px;
  border: 0;
  outline: 0;
  background-color: ${colors.lightWhite};
  opacity: 0.5;
  border-radius: 10px;
  padding: 10px 15px;
`

function ContactUs() {
  return (
    <Container>
      <InnerContainer>
        <AppinputWithLabel labelfor="firstName" labelText="First Name" />
        <AppinputWithLabel labelfor="lastName" labelText="Last Name" />
        <AppinputWithLabel labelfor="email" labelText="Email Id" />
        <AppinputWithLabel labelfor="phone" labelText="Phone Number" />
      </InnerContainer>
      <TextArea>
        <Label for="message">Your Message</Label>
        <InputBox type="text" id="message" />
        <Instruction>
          Please fill your enquiry and/or feedback in the above form and submit.
        </Instruction>
        <AppButtonFill buttonText="SUBMIT" />
      </TextArea>
    </Container>
  )
}

export default ContactUs
