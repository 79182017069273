import { TbFileCertificate } from "react-icons/tb"
import { HiLightBulb } from "react-icons/hi"
import { FaHandsHelping } from "react-icons/fa"
import { SiHandshake } from "react-icons/si"
import { FaFlagCheckered } from "react-icons/fa"

import colors from "../../Colors"

const ConductList = [
  {
    id: "1",
    icon: <TbFileCertificate size={30} color={colors.orange} />,
    title: "COURTESY",
    description:
      "Every one should be Fair, Respect others, be Polite, be Helpful and Kind.",
  },
  {
    id: "2",
    icon: <HiLightBulb size={30} color={colors.orange} />,
    title: "CONSIDERATION",
    description:
      "Think about other people and how they feel. Take into account others suggestions and opinions",
  },
  {
    id: "3",
    icon: <FaHandsHelping size={30} color={colors.orange} />,
    title: "CO-OPERATION",
    description:
      "Give other people a chance. Work together safely. share when appropriate.",
  },
  {
    id: "4",
    icon: <SiHandshake size={30} color={colors.orange} />,
    title: "CARING",
    description:
      "Care about other people. Use equipment safely. Treat all things as you treat your things.",
  },
  {
    id: "5",
    icon: <FaFlagCheckered size={30} color={colors.orange} />,
    title: "COURAGE",
    description: "Stand up for things you think are right. Try out new things.",
  },
]

export default ConductList
