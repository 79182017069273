import React from "react"
import styled from "styled-components"
import colors from "../../Colors"
import AppOrangeRing from "../../ReusableComponents/AppOrangeRing"

import AppTitleCentered from "../../ReusableComponents/AppTitleCentered"
import YouTube from "react-youtube"

const Container = styled.div`
  width: 100%;
  background-color: ${colors.lightOrange};
  padding: 30px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justifycontent: space-between;
  position: relative;
  z-index: 0;
`
const Image = styled.div`
  width: 900px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  place-items: center;
  z-index: 0;
  iframe {
    width: 900px;
    height: 460px;
    margin: auto auto;
  }
`

function Watchus() {
  const opts = {
    height: "1200",
    width: "400",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  }

  const ready = (event) => {
    event.target.pauseVideo()
  }
  return (
    <Container>
      <AppOrangeRing
        width={"40px"}
        height={"40px"}
        radius={"20px"}
        top={"50%"}
        left={"30px"}
        borderWidth={"10px"}
      />
      <AppOrangeRing
        width={"100px"}
        height={"100px"}
        radius={"50px"}
        top={"100px"}
        right={"60px"}
        borderWidth={"10px"}
      />
      <AppOrangeRing
        width={"60px"}
        height={"60px"}
        radius={"30px"}
        top={"200px"}
        right={"30px"}
        borderWidth={"10px"}
      />
      <AppTitleCentered
        title="Watch Us"
        subTitle="Start Growing With Community"
      />
      <Image>
        <YouTube
          videoId="d6XE_BX2KfU"
          opts={opts}
          onReady={(event) => ready(event)}
        ></YouTube>
      </Image>
    </Container>
  )
}

export default Watchus
