import React, { useState } from "react"
import styled from "styled-components"
import colors from "../../Colors"
import AppRouteTitle from "../../ReusableComponents/AppRouteTitle"
import AppRouteSubTitle from "../../ReusableComponents/AppRouteSubTitle"
import schoolImage from "./Assets/MiddleSchool_5.jpg"
import FacilitatorsList from "./FacilitatorsList"
import FacilitatorCard from "./FacilitatorCard"

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin 30px auto;
`
const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
`

const Image = styled.img`
  width: 100%;
  height: 70vh;
  object-fit: cover;
`

const ContentContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin: 30px auto;
`

const Content = styled.p`
  width: 100%;
  text-align: left;
  margin: 20px auto;
  font-size: 1.1rem;
  color: ${colors.mediumDark};
  font-weight: 500;
`

const ImagesContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
`

const ImageDiv = styled.div`
  width: 250px;
  height: 400px;
  border-radius: 10px;
  margin: 10px;
`

function Facilitators() {
  const DATA = FacilitatorsList
  const [data, setData] = useState(DATA)

  const HandleChange = (id) => {
    const AlteredData = data.map((item) => {
      if (item.id == id) {
        return {
          ...item,
          show: true,
        }
      } else
        return {
          ...item,
          show: false,
        }
    })

    setData(AlteredData)
    console.log(id)
  }
  const HandleLeave = () => {
    const AlteredData = data.map((item) => {
      return {
        ...item,
        show: false,
      }
    })
    setData(AlteredData)
  }

  return (
    <Container>
      <ImageContainer>
        <Image src={schoolImage} alt="classroom" />
      </ImageContainer>
      <ContentContainer>
        <AppRouteTitle titleText="Facilitators" />
        <AppRouteSubTitle titleText="Our Facilitators" />
        <Content>
          All our facilitators are highly trained teaching professionals with
          loads of patience to work closely with young students. They actively
          participate in the day-to-day activities like dining and reading
          etiquette, watering plants, singing, dancing and physical exercises.
          They leverage the various audio-visual teaching aids for imparting the
          best experiences to our motivated and demanding students.
        </Content>
        <AppRouteSubTitle titleText="Facilitators Profile" />
        <ImagesContainer>
          {data.map((item) => (
            <FacilitatorCard
              item={item}
              change={HandleChange}
              leave={HandleLeave}
            />
          ))}
        </ImagesContainer>
      </ContentContainer>
    </Container>
  )
}

export default Facilitators
