import React from "react"
import styled from "styled-components"
import colors from "../../Colors"

import User from "./Assets/user.jpg"

const CardContainer = styled.div`
  width: 100%;
  background-color: ${(props) =>
    props.number % 2 == 0 ? colors.lightOrange : colors.white};
`

const InnerContainer = styled.div`
  width: 80%;
  display: grid;
  grid-template-columns: 0.3fr 1fr;
  grid-template-rows: 1fr;
  gap: 20px;
  align-items: flex-start;
  justify-items: flex-start;
  margin: 20px auto;
`
const ParentDetails = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`
const UserImage = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50px;
  object-fit: cover;
  border: 1px solid ${colors.lightWhite};
`
const FeedbackContent = styled.p`
  font-size: 1.1rem;
  font-weight: 500;
  color: ${colors.mediumDark};
  text-align: left;
`
function FeedbackCard({ item }) {
  return (
    <CardContainer number={parseInt(item.id)}>
      <InnerContainer>
        <ParentDetails>
          <UserImage src={User} alt="user" />
          <p>{item.name}</p>
          <p>
            <span>{item.star}</span>
            <span>{item.star}</span>
            <span>{item.star}</span>
            <span>{item.star}</span>
            <span>{item.star}</span>
          </p>
        </ParentDetails>
        <FeedbackContent>{item.feedback}</FeedbackContent>
      </InnerContainer>
    </CardContainer>
  )
}

export default FeedbackCard
