import * as React from "react"
import ImageList from "@mui/material/ImageList"
import ImageListItem from "@mui/material/ImageListItem"
import ImageListItemBar from "@mui/material/ImageListItemBar"

import image1 from "./Assets/1.jpg"
import image2 from "./Assets/2.jpg"
import image3 from "./Assets/3.jpg"
import image4 from "./Assets/4.jpg"
import image5 from "./Assets/5.jpg"
import image6 from "./Assets/6.jpg"
import image7 from "./Assets/7.jpg"
import image8 from "./Assets/8.jpg"
import image9 from "./Assets/9.jpg"
import image10 from "./Assets/10.jpg"
import image11 from "./Assets/11.jpg"
import image12 from "./Assets/12.jpg"
import image13 from "./Assets/13.jpg"
import image14 from "./Assets/14.jpg"
import image15 from "./Assets/15.jpg"
import image16 from "./Assets/16.jpg"
import image17 from "./Assets/17.jpg"
import image18 from "./Assets/18.jpg"
import image19 from "./Assets/19.jpg"

export default function ImageGalleryList() {
  return (
    <ImageList sx={{ width: "100%", height: "auto", margin: "30px auto" }}>
      {itemData.map((item) => (
        <ImageListItem key={item.img}>
          <img
            src={`${item.img}?w=248&fit=crop&auto=format`}
            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
            alt={item.title}
            loading="lazy"
          />
          <ImageListItemBar
            title={item.title}
            subtitle={<span>{item.author}</span>}
            position="below"
          />
        </ImageListItem>
      ))}
    </ImageList>
  )
}

const itemData = [
  {
    img: image1,
    title: "Conference Hall",
    author: "Meeting Held to discuss child development strategy",
  },
  {
    img: image2,
    title: "Annual Day",
    author:
      "Our first annual day function on 2012, Rajadhani Gardens, New Maruthi nagar, Kothapet",
  },
  {
    img: image3,
    title: "A wining moment for our beloved children",
    author: "City Finale 2019-2020",
  },
  {
    img: image4,
    title: "A wining moment for our beloved children",
    author: "City Finale 2019-2020",
  },
  {
    img: image5,
    title: "A wining moment for our beloved children",
    author: "City Finale 2019-2020",
  },
  {
    img: image6,
    title: "Gathering of our children on awareness programme",
    author:
      "We believe that a child shoudl develop not just through studies or books but also from real life experiences",
  },
  {
    img: image7,
    title: "Parents Teacher Meet",
    author:
      "We believe that a bond between parents and teachers should be there",
  },
  {
    img: image8,
    title: "Staff Room",
    author: "Teachers Gathering",
  },
  {
    img: image9,
    title: "Cultural Activity on Guru Purnima",
    author:
      "We strive to bring out the child's ability to find their way to their likings",
  },
  {
    img: image10,
    title: "Weekly Programs - Laughing Exercise",
    author:
      "Children should be activily socialize with the other people as they grow they tend to meet new people",
  },
  {
    img: image11,
    title: "Class Representative Programme",
    author:
      "Teachers let students teach once in a while to analyse their skills",
  },
  {
    img: image12,
    title: "Weekly Programme - Planatation",
    author:
      "We teach children how to grow plants and their benefits, to teach the importance of greenary",
  },
  {
    img: image13,
    title: "Weekly Programme - Planatation",
    author:
      "We teach children how to grow plants and their benefits, to teach the importance of greenary",
  },
  {
    img: image14,
    title: "Weekly Programme - Gardening",
    author:
      "Having pets is overrated, Growing plants isn't. We teach children about gardening",
  },
  {
    img: image15,
    title: "Weekly Programme - Farming",
    author:
      "One should never forget the roots of our ancestors, Farming should also be a part of education",
  },
  {
    img: image16,
    title: "Health Checkup camp",
    author: "Health checkup for students",
  },
  {
    img: image17,
    title: "Talents of our children",
    author: "Certification for the excellence of our students",
  },
  {
    img: image18,
    title: "Weekly Programme -  Awareness on Good / Bad touch",
    author:
      "An awarenesss to kindergarden children about harmfull and goodness of touching things",
  },
  {
    img: image19,
    title: "Cultural Events",
    author:
      "Bringing out the artists of our children to find their path to the life",
  },
]
