const NavBarLinks = [
  {
    id: "1",
    text: "Home",
    link: "#HOME",
  },
  {
    id: "2",
    text: "About Us",
    link: "#ABOUTUS",
  },
  {
    id: "3",
    text: "Courses",
    link: "#COURSES",
  },
  {
    id: "4",
    text: "Events",
    link: "#EVENTS",
  },
  {
    id: "5",
    text: "Blogs",
    link: "#BLOGS",
  },
  {
    id: "6",
    text: "Testimonials",
    link: "#TESTIMONIALS",
  },
]

const FooterQuickLinks = [
  {
    id: "1",
    text: "JSL School",
    link: "jslschool",
  },
  {
    id: "2",
    text: "Day Care",
    link: "daycare",
  },
  {
    id: "3",
    text: "Awards",
    link: "awards",
  },
  {
    id: "4",
    text: "Gallery",
    link: "photos",
  },
  {
    id: "5",
    text: "Feedback",
    link: "parentsfeedback",
  },
  {
    id: "6",
    text: "Contact Us",
    link: "contactus",
  },
]

export { NavBarLinks, FooterQuickLinks }
