import logo from "./logo.svg"
import "./App.css"
import RouterPage from "./Components/RouterPage/RouterPage"
import HomePage from "./Components/HomePage/HomePage"

function App() {
  return (
    <div className="App">
      <RouterPage />
    </div>
  )
}

export default App
