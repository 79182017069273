import React from "react"
import colors from "../../Colors"
import styled from "styled-components"

const CardContainer = styled.div`
  width: 210px;
  height: 210px;
  border-radius: 20px;
  padding: 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
`

const IconContainer = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  border: 1px solid ${colors.orange};
  align-items: center;
  justify-content: center;
  border-radius: 30px;
`

const CardTitle = styled.p`
  font-size: 1.2rem;
  font-weight: 700;
  color: ${colors.dark};
`
const CardDescription = styled.p`
  font-size: 0.9rem;
  font-weight: 400;
  color: ${colors.lightGrey};
`

function ServiceCard({ icon, title, subTitle }) {
  return (
    <CardContainer>
      <IconContainer>{icon}</IconContainer>
      <CardTitle>{title}</CardTitle>
      <CardDescription>{subTitle}</CardDescription>
    </CardContainer>
  )
}

export default ServiceCard
