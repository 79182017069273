import React from "react"
import AppTitle from "../../ReusableComponents/AppTitle"
import colors from "../../Colors"
import styled from "styled-components"
import SwiperComponent from "./SwiperComponent"

import { TbMessages } from "react-icons/tb"
import AppOrangeRing from "../../ReusableComponents/AppOrangeRing"

const MainContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 30px;
  background-color: ${colors.lightOrange};
  position: relative;
  z-index: -2;
`
const MessagesIcon = styled.div`
  position: absolute;
  top: 50px;
  left: 30vw;
  opacity: 0.2;
`
const InnerContainer = styled.div`
  width: 80%;
  box-sizing: border-box;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`
const StaticContent = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
`
const DynamicContent = styled.div`
  width: 50%;
  height: 300px;
`

const TestimonialDescription = styled.p`
  width: 80%;
  text-align: left;
  color: ${colors.dark};
  margin: 10px;
  line-height: 1.3rem;
`

function Testimonials() {
  return (
    <MainContainer id="TESTIMONIALS">
      <AppOrangeRing
        width={"60px"}
        height={"60px"}
        radius={"30px"}
        bottom={"10%"}
        left={"50px"}
        borderWidth={"10px"}
      />
      <AppOrangeRing
        width={"60px"}
        height={"60px"}
        radius={"30px"}
        top={"10%"}
        right={"50px"}
        borderWidth={"10px"}
      />
      <MessagesIcon>
        <TbMessages size={250} color={colors.orange} />
      </MessagesIcon>
      <InnerContainer>
        <StaticContent>
          <AppTitle title="Testimonials" subTitle="See What Our Clients Says" />
          <TestimonialDescription>
            We believe that with our ways and actions we make children into
            their best versions. And those results amazed everyone and with
            their feedback we are motiviated to do some more.
            <br />
            <p>Here are some of the feedbacks from our clients</p>
          </TestimonialDescription>
        </StaticContent>
        <DynamicContent>
          <SwiperComponent />
        </DynamicContent>
      </InnerContainer>
    </MainContainer>
  )
}

export default Testimonials
