import Carousel from "react-bootstrap/Carousel"
import styled from "styled-components"
import colors from "../../Colors"
import image1 from "./Assets/banner5.jpg"
import image2 from "./Assets/MiddleSchool_1.jpg"
import image3 from "./Assets/banner9.jpg"

const Image = styled.img`
  width: 100%;
  height: 500px;
  object-fit: cover;
`

const Container = styled.div`
  width: 100%;
  height: auto;
  margin: 20px auto;
`

const CarousalHeading = styled.p`
  font-size: 32px;
  font-weight: 700;
  color: ${colors.white};
`
const CarousalSubHeading = styled.p`
  font-size: 24px;
  font-weight: 500;
  color: ${colors.white};
`

function CarouselComp() {
  return (
    <Container>
      <Carousel fade>
        <Carousel.Item>
          <Image src={image1} alt="school" />
          <Carousel.Caption>
            <CarousalHeading>ACADEMIC EXCELLENCE</CarousalHeading>
            <CarousalSubHeading>
              More than a decade of experience in educational sector
            </CarousalSubHeading>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <Image src={image2} alt="school" />

          <Carousel.Caption>
            <CarousalHeading>LOVE FOR LEARNING</CarousalHeading>
            <CarousalSubHeading>
              We create an envirnoment that improves love for learning
            </CarousalSubHeading>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          {/* <img
          className="d-block w-100"
          src="holder.js/800x400?text=Third slide&bg=20232a"
          alt="Third slide"
        /> */}
          <Image src={image3} alt="school" />
          <Carousel.Caption>
            <CarousalHeading>BUILDS CHARACTER AND INTEGRITY</CarousalHeading>
            <CarousalSubHeading>
              We believe that building a character and integrity to the child is
              also as important as education.
            </CarousalSubHeading>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </Container>
  )
}

export default CarouselComp
