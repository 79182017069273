import React from "react"
import styled from "styled-components"
import colors from "../Colors"

const Container = styled.div`
width : 100%,
margin : 10px auto;

`
const Label = styled.label`
  margin: 5px;
  font-size: 1.1rem;
  color: ${colors.mediumDark};
  font-weight: 500;
  text-align: left;
`

const InputBox = styled.input`
  width: 100%;
  border: 0;
  outline: 0;
  background-color: ${colors.lightWhite};
  opacity: 0.5;
  border-radius: 10px;
  padding: 10px 15px;
`

function AppinputWithLabel({ labelfor, labelText }) {
  return (
    <Container>
      <Label for={labelfor}>{labelText}</Label>
      <InputBox type="text" id={labelfor} />
    </Container>
  )
}

export default AppinputWithLabel
