import React from "react"
import { Carousel } from "react-responsive-carousel"
import styled from "styled-components"
import colors from "../../Colors"

const Container = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`
const ImageContiner = styled.img`
  width: 100% !important;
  height: 80vh;
  object-fit: cover;
  margin: auto;
`

function CarousalComp({ item }) {
  //   console.log(item)
  return (
    <Container>
      <Carousel
        animationHandler="fade"
        infiniteLoop
        autoPlay={true}
        interval={4000}
        stopOnHover={true}
      >
        {item.map((images) => (
          <ImageContiner src={images.image} />
        ))}
      </Carousel>
    </Container>
  )
}

export default CarousalComp
