import React from "react"
import colors from "../../Colors"
import styled from "styled-components"
import { BsFillCalendarWeekFill } from "react-icons/bs"

const BlogCard = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`

const BlogImage = styled.img`
  width: 300px;
  height: 300px;
  object-fit: fill;
  object-position: center;
`

const DateButton = styled.div`
  padding: 15px 20px;
  background-color: ${colors.orange};
  color: ${colors.white};
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  position: relative;
  bottom: 25px;
  margin-bottom: -25px;
`

const BlogDescription = styled.p`
  font-size: 0.9rem;
  font-weight: 500;
  color: ${colors.dark};
  width: 90%;
  height: 60px;
  text-align: center;
  margin: 20px auto;
`
function BlogPost({ item }) {
  return (
    <BlogCard>
      <BlogImage src={item.image} alt="blog-post" />
      <DateButton>
        <BsFillCalendarWeekFill size={20} color={colors.white} />
        {item.date}
      </DateButton>
      <BlogDescription>{item.description}</BlogDescription>
    </BlogCard>
  )
}

export default BlogPost
