import React from "react"
import colors from "../../Colors"
import styled from "styled-components"
import AppTitleCentered from "../../ReusableComponents/AppTitleCentered"
import BlogsList from "./BlogsList"
import BlogPost from "./BlogPost"
import AppButtonFill from "../../ReusableComponents/AppButtonFill"
import AppOrangeRing from "../../ReusableComponents/AppOrangeRing"

const Container = styled.div`
  width: 100%;
  padding: 30px;
  box-sizing: border-box;
  position: relative;
`
const InnerContainer = styled.div`
  width: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: auto;
`

const BlogPosts = styled.div`
  display: flex;
  align: center;
  justify-content: space-between;
  gap: 30px;
  margin: 30px;
`
function OurBlog() {
  return (
    <Container id="BLOGS">
      <AppOrangeRing
        width={"60px"}
        height={"60px"}
        radius={"30px"}
        top={"30%"}
        left={"30px"}
        borderWidth={"10px"}
      />
      <AppOrangeRing
        width={"100px"}
        height={"100px"}
        radius={"50px"}
        bottom={"30%"}
        right={"-10px"}
        borderWidth={"10px"}
      />
      <InnerContainer>
        <AppTitleCentered title="Our Blog" subTitle="Latest News Feed" />
        <BlogPosts>
          {BlogsList.map((item) => (
            <BlogPost item={item} />
          ))}
        </BlogPosts>
        <AppButtonFill buttonText="Read More Our Blogs" />
      </InnerContainer>
    </Container>
  )
}

export default OurBlog
