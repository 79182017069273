import * as React from "react"
import { Routes, Route, Outlet, Link } from "react-router-dom"
import ChairmanMessage from "../../RoutedScreens/ChairmanMessage/ChairmanMessage"
import DirectMessage from "../../RoutedScreens/DirectorMessage/DirectMessage"
import Facilitators from "../../RoutedScreens/Facilitators/Facilitators"
import Leadership from "../../RoutedScreens/Leadership/Leadership"
import AboutUs from "../../RoutedScreens/AboutUs/AboutUs"
import HomePage from "../HomePage/HomePage"
import NavBarComp from "../Navbar/NavBarComp"
import Footer from "../Footer/Footer"
import DayCare from "../../RoutedScreens/DayCare/DayCare"
import JSLSchool from "../../RoutedScreens/JSLSchool/JslSchool"
import Awards from "../../RoutedScreens/Awards/Awards"
import Photos from "../../RoutedScreens/Photos/Photos"
import Videos from "../../RoutedScreens/Videos/Videos"
import ParentsFeedback from "../../RoutedScreens/ParentFeedback/ParentsFeedback"
import TestimonialVideos from "../../RoutedScreens/TestimonialVideos/TestimonialVideos"
import ContactUs from "../../RoutedScreens/ContactUs/ContactUs"
import ScrollToTop from "./ScrollToTop"

export default function RouterPage() {
  return (
    <div>
      <ScrollToTop />
      <NavBarComp />
      <Outlet />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="aboutus" element={<AboutUs />} />
        <Route path="chairmanmessage" element={<ChairmanMessage />} />
        <Route path="directormessage" element={<DirectMessage />} />
        <Route path="leadership" element={<Leadership />} />
        <Route path="facilitators" element={<Facilitators />} />
        <Route path="daycare" element={<DayCare />} />
        <Route path="jslschool" element={<JSLSchool />} />
        <Route path="awards" element={<Awards />} />
        <Route path="photos" element={<Photos />} />
        <Route path="videos" element={<Videos />} />
        <Route path="parentsfeedback" element={<ParentsFeedback />} />
        <Route path="testimonialvideos" element={<TestimonialVideos />} />
        <Route path="contactus" element={<ContactUs />} />
      </Routes>
      <Footer />
    </div>
  )
}
