import srinivas from "./Assets/srinivas.jpg"

const TestimonialList = [
  {
    id: "1",
    image: { srinivas },
    name: "Pakali Srinivas",
    profession: "Parent of our beloved Student",
    description:
      "I can definitely say that this is the best and unique school for kids of today's generation. They not only teach academics, but also teach ethics and moral values to students.",
  },
  {
    id: "2",
    image: { srinivas },
    name: "Pakali Srinivas",
    profession: "Parent of our beloved Student",
    description:
      "I can definitely say that this is the best and unique school for kids of today's generation. They not only teach academics, but also teach ethics and moral values to students.",
  },
  {
    id: "3",
    image: { srinivas },
    name: "Pakali Srinivas",
    profession: "Parent of our beloved Student",
    description:
      "I can definitely say that this is the best and unique school for kids of today's generation. They not only teach academics, but also teach ethics and moral values to students.",
  },
  {
    id: "4",
    image: { srinivas },
    name: "Pakali Srinivas",
    profession: "Parent of our beloved Student",
    description:
      "I can definitely say that this is the best and unique school for kids of today's generation. They not only teach academics, but also teach ethics and moral values to students.",
  },
  {
    id: "5",
    image: { srinivas },
    name: "Pakali Srinivas",
    profession: "Parent of our beloved Student",
    description:
      "I can definitely say that this is the best and unique school for kids of today's generation. They not only teach academics, but also teach ethics and moral values to students.",
  },
  {
    id: "6",
    image: { srinivas },
    name: "Pakali Srinivas",
    profession: "Parent of our beloved Student",
    description:
      "I can definitely say that this is the best and unique school for kids of today's generation. They not only teach academics, but also teach ethics and moral values to students.",
  },
  {
    id: "7",
    image: { srinivas },
    name: "Pakali Srinivas",
    profession: "Parent of our beloved Student",
    description:
      "I can definitely say that this is the best and unique school for kids of today's generation. They not only teach academics, but also teach ethics and moral values to students.",
  },
]

export default TestimonialList
