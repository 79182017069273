import React, { useState } from "react"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"
import styled from "styled-components"
import colors from "../../Colors"
import CarousalComp from "./CarousalComp"
import {
  others,
  awards1,
  awards2,
  awards3,
  awards4,
  awards5,
  awards6,
  awards7,
} from "./AwardImages"

import ToggleButton from "@mui/material/ToggleButton"
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup"

import AppRouteTitle from "../../ReusableComponents/AppRouteTitle"
import AppRouteSubTitle from "../../ReusableComponents/AppRouteSubTitle"

const Container = styled.div`
  width: 100%;
  height: 100%;
  margin: 30px;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  gap: 20px;
`
const YearContainer = styled.div`
  margin-top: 140px;
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-contnent: center;
`
const CarousalCOntainer = styled.div`
  margin-top: 50px;
  width: 60%;
  height: 100%;
`
const ContentContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin 30px auto;

`
const Subheading = styled.div`
  font-size: 1.3rem;
  text-align: center;
  color: ${colors.dark};
  font-weight: 500;
`
const ImageTitle = styled.p`
  font-size: 2rem;
  font-weight: 600;
  text-align: Left;
  margin: 20px;
  color: ${colors.dark};
`
function Awards() {
  const [awardItems, setAwardItems] = useState(awards7)

  const handleChange = (event, nextView) => {
    setAwardItems(nextView)
  }
  return (
    <div style={{ width: "95%", margin: "30px auto" }}>
      <ContentContainer>
        <AppRouteTitle titleText="AWARDS" />
        <Subheading>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut
        </Subheading>
      </ContentContainer>
      <Container>
        <YearContainer>
          <ToggleButtonGroup
            orientation="vertical"
            value={awardItems}
            exclusive
            onChange={handleChange}
          >
            <ToggleButton
              sx={{
                fontSize: "1rem",
                fontWeight: "600",
                padding: "5px",
                // backgroundColor: colors.lightOrange,
              }}
              value={awards7}
              aria-label="list"
            >
              <p
                style={{
                  margin: "0",
                  padding: "8px 12px",
                  color: colors.orange,
                }}
              >
                2019-2020
              </p>
            </ToggleButton>
            <ToggleButton
              sx={{
                fontSize: "1rem",
                fontWeight: "600",
                padding: "5px",
                // backgroundColor: colors.lightOrange,
              }}
              value={awards6}
              aria-label="module"
            >
              <p
                style={{
                  margin: "0",
                  padding: "8px 12px",
                  color: colors.orange,
                }}
              >
                2018-2019
              </p>
            </ToggleButton>
            <ToggleButton
              sx={{
                fontSize: "1rem",
                fontWeight: "600",
                // backgroundColor: colors.lightOrange,
              }}
              value={awards5}
              aria-label="quilt"
            >
              <p
                style={{
                  margin: "0",
                  padding: "8px 12px",
                  color: colors.orange,
                }}
              >
                2017-2018
              </p>
            </ToggleButton>
            <ToggleButton
              sx={{
                fontSize: "1rem",
                fontWeight: "600",
                padding: "5px",
                // backgroundColor: colors.lightOrange,
              }}
              value={awards4}
              aria-label="quilt"
            >
              <p
                style={{
                  margin: "0",
                  padding: "8px 12px",
                  color: colors.orange,
                }}
              >
                2016-2017
              </p>
            </ToggleButton>
            <ToggleButton
              sx={{
                fontSize: "1rem",
                fontWeight: "600",
                padding: "5px",
                // backgroundColor: colors.lightOrange,
              }}
              value={awards3}
              aria-label="quilt"
            >
              <p
                style={{
                  margin: "0",
                  padding: "8px 12px",
                  color: colors.orange,
                }}
              >
                2015-2016
              </p>
            </ToggleButton>
            <ToggleButton
              sx={{
                fontSize: "1rem",
                fontWeight: "600",
                padding: "5px",
                // backgroundColor: colors.lightOrange,
              }}
              value={awards2}
              aria-label="quilt"
            >
              <p
                style={{
                  margin: "0",
                  padding: "8px 12px",
                  color: colors.orange,
                }}
              >
                2014-2015
              </p>
            </ToggleButton>
            <ToggleButton
              sx={{
                fontSize: "1rem",
                fontWeight: "600",
                padding: "5px",
                // backgroundColor: colors.lightOrange,
              }}
              value={awards1}
              aria-label="quilt"
            >
              <p
                style={{
                  margin: "0",
                  padding: "8px 12px",
                  color: colors.orange,
                }}
              >
                2013-2014
              </p>
            </ToggleButton>
            <ToggleButton
              sx={{
                fontSize: "1rem",
                fontWeight: "600",
                padding: "5px",
                // backgroundColor: colors.lightOrange,
              }}
              value={others}
              aria-label="quilt"
            >
              <p
                style={{
                  margin: "0",
                  padding: "8px 12px",
                  color: colors.orange,
                }}
              >
                Award Ceremonies- Facebook Albums
              </p>
            </ToggleButton>
          </ToggleButtonGroup>
        </YearContainer>
        <CarousalCOntainer>
          {awardItems && <ImageTitle>{awardItems[0].title}</ImageTitle>}
          {awardItems && <CarousalComp item={awardItems} />}
        </CarousalCOntainer>
      </Container>
    </div>
  )
}

export default Awards
