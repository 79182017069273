import React from "react"
import colors from "../../Colors"
import styled from "styled-components"

import AppTitle from "../../ReusableComponents/AppTitle"
import AppButtonFill from "../../ReusableComponents/AppButtonFill"

// Icons

import { GiTeacher } from "react-icons/gi"
import { BsAwardFill } from "react-icons/bs"
import { IoLibrarySharp } from "react-icons/io5"
import { FaChalkboardTeacher } from "react-icons/fa"
import { GiDrawbridge } from "react-icons/gi"
import { FaHandHoldingMedical } from "react-icons/fa"
import ServiceCard from "./ServiceCard"
import AppOrangeRing from "../../ReusableComponents/AppOrangeRing"

const Container = styled.div`
  width: 100%;
  padding: 30px;
  position: relative;
`

const InnerContainer = styled.div`
  width: 85%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px auto;
`
const CardDescription = styled.p`
  font-size: 1.1rem;
  margin: 10px;
  text-align: left;
  line-height: 1.5rem;
`
const LeftContainer = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
`
const RightContainer = styled.div`
  width: 70%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 10px;
`

function Services() {
  return (
    <Container>
      <AppOrangeRing
        width={"60px"}
        height={"60px"}
        radius={"30px"}
        bottom={"20px"}
        left={"30px"}
        borderWidth={"10px"}
      />
      <AppOrangeRing
        width={"80px"}
        height={"80px"}
        radius={"50px"}
        top={"30%"}
        right={"50px"}
        borderWidth={"10px"}
      />
      <InnerContainer>
        <LeftContainer>
          <AppTitle title="Services" subTitle="What We Provide To You" />
          <CardDescription>
            we believe strong children make the nation strong when they grow up.
            JSL doesn't only focuses on education and academics. We believe that
            every child has a right to grow mentally and physically. To make
            that happen we provide an environment where a child can build
            himself and grow up to be stronger. We provide an environment where
            it not only bringout the best in academics but also in every aspect.
          </CardDescription>
          <div style={{ margin: "10px" }}>
            <AppButtonFill buttonText="Know More About Services" />
          </div>
        </LeftContainer>
        <RightContainer>
          <ServiceCard
            icon={<GiTeacher size={30} color={colors.orange} />}
            title="Skilled Teachers"
            subTitle="we are proud to say we have highly skilled and ambigious teachers"
          />
          <ServiceCard
            icon={<BsAwardFill size={30} color={colors.orange} />}
            title="Scholarship"
            subTitle="We also provide scholarships for the merit"
          />
          <ServiceCard
            icon={<IoLibrarySharp size={30} color={colors.orange} />}
            title="Book Library"
            subTitle="A well developed and organised library for the students"
          />
          <ServiceCard
            icon={<FaChalkboardTeacher size={30} color={colors.orange} />}
            title="Online Classes"
            subTitle="We also conduct online classes for the children in need"
          />
          <ServiceCard
            icon={<GiDrawbridge size={30} color={colors.orange} />}
            title="Home Projects"
            subTitle="Making projects on their own is a great way of self learning"
          />
          <ServiceCard
            icon={<FaHandHoldingMedical size={30} color={colors.orange} />}
            title="24x7 Support"
            subTitle="For child support and child safety, we are available 24x7"
          />
        </RightContainer>
      </InnerContainer>
    </Container>
  )
}

export default Services
