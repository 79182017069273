import React from "react"
import colors from "../../Colors"
import styled from "styled-components"

import { IoTimeSharp } from "react-icons/io5"
import { HiLocationMarker } from "react-icons/hi"

const CardContainer = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`

const CardSchedule = styled.div`
  min-width: 150px;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${colors.lightOrange};
  border-radius: 20px;
`

const CardDate = styled.p`
  font-size: 2.5rem;
  font-weight: 700;
  color: ${colors.orange};
  line-height: 1rem;
  margin: 10px;
`
const CardMonth = styled.p`
  font-size: 0.9rem;
  font-weight: 500;
  color: ${colors.black};
  line-height: 1rem;
`
const CardTitle = styled.p`
  font-size: 1.1rem;
  font-weight: 600;
  color: ${colors.black};
  line-height: 1rem;
  margin: 5px;
`
const DetailInternal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const CardPlace = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const CardTime = styled.p`
  font-size: 0.9rem;
  font-weight: 600;
  color: ${colors.orange};
  margin: 5px;
`
const CardDescription = styled.p`
  font-size: 0.9rem;
  font-weight: 600;
  color: ${colors.lightGrey};
  margin: 5px;
  text-align: justify;
`

function EventCard({ item }) {
  console.log(item)
  return (
    <CardContainer>
      <CardSchedule>
        <CardDate>{item.date}</CardDate>
        <CardMonth>{item.monthAndYear}</CardMonth>
      </CardSchedule>
      <div>
        <CardTitle>{item.eventTitle}</CardTitle>
        <CardPlace>
          <DetailInternal>
            <IoTimeSharp size={20} color={colors.orange} />
            <CardTime>
              {item.startTime} - {item.endTime}
            </CardTime>
          </DetailInternal>
          <DetailInternal>
            <HiLocationMarker size={20} color={colors.orange} />
            <CardTime>{item.location}</CardTime>
          </DetailInternal>
        </CardPlace>
        <CardDescription>{item.description}</CardDescription>
      </div>
    </CardContainer>
  )
}

export default EventCard
