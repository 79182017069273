import React from "react"
import colors from "../../Colors"
import styled from "styled-components"

import AppTitleCentered from "../../ReusableComponents/AppTitleCentered"
import AppButtonFill from "../../ReusableComponents/AppButtonFill"

const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin: auto;
  padding: 30px 0px;
  background-color: ${colors.lightOrange};
`

const MapFrame = styled.div`
  margin: 20px auto;
`

const ContactDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`

const ContactText = styled.p`
  font-size: 1.2rem;
  line-height: 1rem;
  font-weight: 500;
`
function Map() {
  return (
    <Container>
      <AppTitleCentered
        title="Our Location"
        subTitle="Wanna Know More About us ? Come visit us"
      />
      <MapFrame>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3807.693592342443!2d78.5399268145113!3d17.37847100763722!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb98dd7757de41%3A0x996ddc321e692865!2sJagadeeswari%20School%20of%20Learning!5e0!3m2!1sen!2sin!4v1676116680475!5m2!1sen!2sin"
          width="100%"
          height="500"
          style={{ border: 0 }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </MapFrame>
      <ContactDetails>
        <ContactText>
          Call to our office any time to know more about us
        </ContactText>
        <AppButtonFill buttonText="Our Contact Number" />
      </ContactDetails>
    </Container>
  )
}

export default Map
