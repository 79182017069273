import React, { useState } from "react"
import styled from "styled-components"
import colors from "../../Colors"
import Fade from "react-bootstrap/Fade"

const CardContainer = styled.div`
  width: 250px;
  height: 400px;
  border-radius: 10px;
  margin: 10px;
  position: relative;
`
const Image = styled.img`
  width: 250px;
  height: 400px;
  object-fit: cover;
`
const ContentContainer = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 0px;
  left: 0px;
  background-color: rgba(255, 255, 255, 0.9);
  transition: 1s all ease;
`
const PersonName = styled.p`
  font-size: 1.1rem;
  font-weight: 600;
  color: ${colors.orange};
  text-align: center;
`
const PersonEducation = styled.p`
  font-size: 1rem;
  font-weight: 600;
  color: ${colors.dark};
  text-align: center;
`
const PersonDescription = styled.p`
  font-size: 1.1rem;
  font-weight: 600;
  color: ${colors.mediumDark};
  text-align: center;
`
function FacilitatorCard({ item, change, leave }) {
  const Show = item.show

  const handleMouseOver = () => {
    change(item.id)
  }
  const handleMouseLeave = () => {
    leave()
  }
  return (
    <CardContainer
      key={item.id}
      onMouseEnter={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      <Image src={item.image} alt="person" />
      {item.show && (
        <ContentContainer Show>
          <PersonName>{item.name}</PersonName>
          <PersonEducation>{item.education}</PersonEducation>
          <PersonDescription>{item.description}</PersonDescription>
        </ContentContainer>
      )}
    </CardContainer>
  )
}

export default FacilitatorCard
