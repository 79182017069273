import React from "react"
import colors from "../../Colors"
import styled from "styled-components"

const Container = styled.div`
  min-width: 300px;
  height: 300px;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
`

const ContainerImage = styled.img`
  width: 300px;
  height: 300px;
  object-fit: fit;
  object-position: center;
`
const TextContainer = styled.div`
  width: 100%;
  background-color: ${colors.white};
  position: absolute;
  bottom: 0px;
`
const TitleText = styled.p`
  font-size: 1.2rem;
  font-weight: 600;
  color: ${colors.black};
  opacity: 0.7;
  line-height: 1rem;
  margin: 10px;
  margin-left: 20px;
  padding: 5px;
`
const SubText = styled.p`
  font-size: 1rem;
  font-weight: 600;
  color: ${colors.black};
  line-height: 1rem;
  margin: 10px;
  margin-left: 20px;
  opacity: 0.7;
  padding: 5px;
`

function CourseCard({ image, title, subTitle }) {
  return (
    <Container>
      <ContainerImage src={image} alt="course-image" />
      <TextContainer>
        <TitleText>{title}</TitleText>
        <SubText>{subTitle}</SubText>
      </TextContainer>
    </Container>
  )
}

export default CourseCard
