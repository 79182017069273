import React from "react"
import colors from "../Colors"
import styled from "styled-components"

const Button = styled.span`
  font-size: 0.9rem;
  background-color: ${colors.orange};
  color: ${colors.white};
  padding: 12px 16px;
  font-weight: 600;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  transition: all 0.5s ease;
  &:hover {
    opacity: 0.8;
    background-color: ${colors.yellow};
  }
`

function AppButtonFill({ buttonText }) {
  return <Button>{buttonText}</Button>
}

export default AppButtonFill
