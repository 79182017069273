import React from "react"
import styled from "styled-components"
import colors from "../Colors"

const Ring = styled.div`
  position: absolute;
  border: solid ${colors.orange};
  background-color: transparent;
  z-index: -1;
`

function AppOrangeRing({
  width,
  height,
  radius,
  top,
  bottom,
  left,
  right,
  borderWidth,
}) {
  return (
    <Ring
      style={{
        width: width,
        height: height,
        borderRadius: radius,
        top: top,
        bottom: bottom,
        right: right,
        left: left,
        borderWidth: borderWidth,
      }}
    ></Ring>
  )
}

export default AppOrangeRing
