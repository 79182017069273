import React from "react"

import { createTheme, ThemeProvider, styled } from "@mui/material/styles"
import { orange } from "@mui/material/colors"
// import Header from "../HeaderComp/Header"
import Aboutus from "../AboutUs/Aboutus"
import Watchus from "../WatchUs/Watchus"
import Services from "../Services/Services"
import BestCourses from "../BestCourses/BestCourses"
import Events from "../Events/Events"
import Testimonials from "../Testimonials/Testimonials"
import OurBlog from "../OurBlog/OurBlog"
import Map from "../Map/Map"
// import Footer from "../Footer/Footer"
import CarouselComp from "../CarousalComp/CarouselComp"
import NavBarComp from "../Navbar/NavBarComp"
import Footer from "../Footer/Footer"

const theme = createTheme({
  status: {
    danger: orange[500],
  },
})

function HomePage() {
  return (
    <ThemeProvider theme={theme}>
      {/* <NavBarComp /> */}
      {/* <Header /> */}
      <CarouselComp />
      <Aboutus />
      <Watchus />
      <Services />
      <BestCourses />
      <Events />
      <Testimonials />
      <OurBlog />
      <Map />
      {/* <Footer /> */}
    </ThemeProvider>
  )
}

export default HomePage
