import React from "react"
import styled from "styled-components"
import colors from "../../Colors"
import AppRouteTitle from "../../ReusableComponents/AppRouteTitle"

import schoolImage from "./Assets/banner5.jpg"

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin 30px auto;
`
const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
`

const Image = styled.img`
  width: 100%;
  height: 70vh;
  object-fit: cover;
`

const ContentContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
  margin: 30px auto;
`

const Paragraph = styled.p`
  font-size: 1.1rem;
  color: ${colors.mediumDark};
  font-weight: 500;
`
function AboutUs() {
  return (
    <Container>
      <ImageContainer>
        <Image src={schoolImage} alt="classroom" />
      </ImageContainer>
      <ContentContainer>
        <AppRouteTitle titleText="About Us" />
        <Paragraph>
          We believe that thinking should be “out of the box”, not “tick the
          box” and our infectious passion for learning means we achieve more
          than outstanding results: we build confident, creative thinkers and
          deliver an education that is truly relevant to their future.
        </Paragraph>
        <Paragraph>
          We are part of JSL (Jagadeeswari School of Learning) wherein we
          recognize that parents are the first educators of their children.
          Through a system of education which is personalized, we seek to
          integrate the pursuit of academic excellence, the acquisition of
          skills and the development of the student’s character.
        </Paragraph>
        <Paragraph>
          We seek to assist parents to be more effective as educators of their
          children and provide for the overall development of the students.
        </Paragraph>
        <Paragraph>
          We ensure that each child is guided to give his/her very best to every
          pursuit he/she undertakes. It facilitates the most meaningful
          partnership between Parents and the School, to ensure that the
          children experience the best possible holistic and effective
          education.
        </Paragraph>
      </ContentContainer>
    </Container>
  )
}

export default AboutUs
