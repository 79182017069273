import React from "react"
import styled from "styled-components"
import colors from "../Colors"

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const HeaderTitle = styled.p`
  font-size: 1.2rem;
  margin: 10px;
  color: ${colors.yellow};
  font-weight: 500;
`
const SubTitle = styled.p`
  font-size: 2.5rem;
  color: ${colors.dark};
  font-weight: 600;
  margin: 10px;
`

function AppTitleCentered({ title, subTitle }) {
  return (
    <Container>
      <HeaderTitle>{title}</HeaderTitle>
      <SubTitle>{subTitle}</SubTitle>
    </Container>
  )
}

export default AppTitleCentered
