import React from "react"
import styled from "styled-components"
import colors from "../../Colors"
import AppRouteTitle from "../../ReusableComponents/AppRouteTitle"
import AppRouteSubTitle from "../../ReusableComponents/AppRouteSubTitle"

import schoolImage from "./Assets/7.jpg"
import FeedbackList from "./FeedbackList"
import FeedbackCard from "./FeedbackCard"

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin 30px auto;

`

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
`

const Image = styled.img`
  width: 100%;
  height: 70vh;
  object-fit: cover;
`

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin 30px auto;

`

function ParentsFeedback() {
  return (
    <Container>
      <ImageContainer>
        <Image src={schoolImage} alt="classroom" />
      </ImageContainer>
      <ContentContainer>
        <AppRouteTitle titleText="Parents Feedback" />
      </ContentContainer>
      {FeedbackList.map((item) => (
        <FeedbackCard item={item} />
      ))}
    </Container>
  )
}

export default ParentsFeedback
