import React, { useRef, useState } from "react"
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/css"
import "swiper/css/pagination"

import "./Styles.css"

// import required modules
import { Autoplay } from "swiper"
import { Pagination } from "swiper"
import TestimonialCard from "./TestimonialCard"
import TestimonialList from "./TestimonialList"

export default function SwiperComponent() {
  return (
    <>
      <Swiper
        direction={"vertical"}
        autoplay={{
          delay: 1000,
          disableOnInteraction: false,
        }}
        speed={5000}
        loop={true}
        pagination={{
          clickable: true,
        }}
        className="mySwiper"
        modules={[Autoplay, Pagination]}
      >
        {TestimonialList.map((item) => (
          <SwiperSlide>
            <TestimonialCard item={item} />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  )
}
