import React from "react"
import styled from "styled-components"
import colors from "../../Colors"
import AppButtonFill from "../../ReusableComponents/AppButtonFill"
import AppOrangeRing from "../../ReusableComponents/AppOrangeRing"
import AppTitleCentered from "../../ReusableComponents/AppTitleCentered"
import EventCard from "./EventCard"

import EventList from "./EventList"

const Container = styled.div`
  width: 100%;
  padding: 30px;
  box-sizing: border-box;
  margin: 30px auto;
  position: relative;
`
const InnerContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin: auto;
`
const EventCards = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 20px;
  margin: 20px auto;
`

function Events() {
  return (
    <Container>
      <AppOrangeRing
        width={"60px"}
        height={"60px"}
        radius={"30px"}
        bottom={"30%"}
        left={"30px"}
        borderWidth={"10px"}
      />
      <AppOrangeRing
        width={"100px"}
        height={"100px"}
        radius={"50px"}
        top={"33%"}
        right={"30px"}
        borderWidth={"10px"}
      />
      <AppOrangeRing
        width={"60px"}
        height={"60px"}
        radius={"30px"}
        top={"45%"}
        right={"-10px"}
        borderWidth={"10px"}
      />
      <InnerContainer id="EVENTS">
        <AppTitleCentered title="Events" subTitle="Upcoming Events" />
        <EventCards>
          {EventList.map((item) => (
            <EventCard item={item} />
          ))}
        </EventCards>
        <AppButtonFill buttonText="Show More Events" />
      </InnerContainer>
    </Container>
  )
}

export default Events
