import React from "react"
import styled from "styled-components"
import colors from "../../Colors"
import AppRouteTitle from "../../ReusableComponents/AppRouteTitle"

import schoolImage from "./Assets/banner6.jpg"
import chairman from "./Assets/chairman.jpg"

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin 30px auto;

`
const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
`

const Image = styled.img`
  width: 100%;
  height: 70vh;
  object-fit: cover;
`
const MainContent = styled.div`
  margin: 30px auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
`
const ChairmanImageContainer = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Chairmanimage = styled.img`
  width: 200px;
  height: 200px;
  border-radius: 100px;
  object-fit: cover;
  margin-top: 50px;
`
const ChairmanName = styled.p`
  font-size: 1.2rem;
  font-weight: 500;
  color: ${colors.orange};
`
const ContentContainer = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
  margin: auto;
`

const Paragraph = styled.p`
  font-size: 1.1rem;
  color: ${colors.mediumDark};
  font-weight: 500;
`
function ChairmanMessage() {
  return (
    <Container>
      <ImageContainer>
        <Image src={schoolImage} alt="classroom" />
      </ImageContainer>
      <MainContent>
        <ChairmanImageContainer>
          <Chairmanimage src={chairman} alt="chairman-picture" />
          <ChairmanName>- Jallapally Narsi Reddy</ChairmanName>
        </ChairmanImageContainer>
        <ContentContainer>
          <AppRouteTitle titleText="Chairman's Message" />
          <Paragraph>
            We believe that the learning process is instrumental in shaping
            one’s personality and the way they deal with situations in the life.
          </Paragraph>
          <Paragraph>
            In a fast-changing world, driven by technological innovations,
            today’s children (citizens of tomorrow) are warmed up to the ideas
            of education being the key to a well-rounded development instead of
            just a mean to acquire degrees and monetary success in life. The
            shift of thoughts from bookish knowledge to knowledge of life, in
            schools, has brought forth a sea of change.
          </Paragraph>
          <Paragraph>
            Children's holistic development and well-being are always at the
            heart of everything we do at our school.
          </Paragraph>
          <Paragraph>
            Our constant endeavour is to identify children's interests early on
            and develop them into their passion.
          </Paragraph>
          <Paragraph>
            Our educational approach gives opportunities to children to explore
            new frontiers of digital learning and prepare them to be lifelong
            learners.
          </Paragraph>
          <Paragraph>
            Our teachers are our pillars of strength. They teach our children
            not just with their minds but with their hearts, making learning
            enjoyable and rewarding, and instilling in them sound values. They
            inspire our children to believe in themselves and think big. Our
            educational programmes are complemented by state-of the art
            facilities and excellent resources, and we offer numerous
            opportunities to children to engage and excel in sports and
            co-curricular activities.
          </Paragraph>
          <Paragraph>
            I invite you to explore JSL as we rededicate ourselves every day, to
            provide an excellent education for our children.
          </Paragraph>
          <Paragraph>We look forward to your continuous support ….</Paragraph>
        </ContentContainer>
      </MainContent>
    </Container>
  )
}

export default ChairmanMessage
