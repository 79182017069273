import React from "react"
import styled from "styled-components"
import colors from "../../Colors"
import AppRouteTitle from "../../ReusableComponents/AppRouteTitle"
import AppRouteSubTitle from "../../ReusableComponents/AppRouteSubTitle"

import schoolImage from "./Assets/6.jpg"
import ImageGalleryList from "./ImageGalleryList"

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin 30px auto;

`

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
`

const Image = styled.img`
  width: 100%;
  height: 70vh;
  object-fit: cover;
`

const ContentContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin 20px auto;

`
function Photos() {
  return (
    <Container>
      <ImageContainer>
        <Image src={schoolImage} alt="classroom" />
      </ImageContainer>
      <ContentContainer>
        <AppRouteTitle titleText="Photos" />
        <ImageGalleryList />
      </ContentContainer>
    </Container>
  )
}

export default Photos
