import { BsFillStarFill } from "react-icons/bs"

const StarIcon = <BsFillStarFill color="#eab845" size={16} />

const FeedbackList = [
  {
    id: 1,
    name: "Swathi Rapolu",
    star: StarIcon,
    feedback: `When my hubby n I were looking for a K.G for our child, we want her frst experience to be in a
        loving, caring n homely environment where they receive professional care n feel safe, we found
        all those qualities at (K.E.S)Jagadeeswari School of Learning where the English language environment
        was an Xtra gift for us!
        Now our child can speak 2 languages and HAPPY☺️☺️ n SELF CONFIDENT.
        ThanQ for offering such a great K.G with so many activities n real great teachers.
        my daughter ❤ SAI SRINIDHI likes it a lot n so do we.
        From my bottom of the heart I can say K.E.S is the best in all respects
        We would like to ThanQ n your staff for their help in preparing n organising a very gud bday
        party. most important the kids really enjoyed the games and had very good time .
        we would like to ThanQ for ur professional n eductional K.G which is surrounded by a happy n
        caring atmosphere
        We r sure that my kid will never forget all the happy times she had at K.E.S and so won&#39;t we.
        We wish u all the rewards u bring to many and all d best for the future.
        I will gladly recommend K.E.S to other parents who would like to do something very special.
        once again many thanks.
        THANK YOU ❤ PAVANI MAM. I really mean it ❤️`,
  },
  {
    id: 2,
    name: "Pakali Srinivas",
    star: StarIcon,
    feedback: `Hi I am Pakali Srinivas { M.com  D.Ed MR,B.Ed MR} Edify had set-up nice inclusive education
    system to group the students with special need children; its gives an opportunity to special
    children to interact with their fellow classmates as well as bond and learn from them. Edify giving
    better quality of education and they are trying hard to develop academic, personal, social,
    occupational and recreational skills. It helps a lot to special child to work independently.and
    School environment is barrier free environment to special children and specially Pavani madam
    dealing with students....no words to explain.,`,
  },
  {
    id: 3,
    name: "Sreeharika K",
    star: StarIcon,
    feedback: `I can definitely say that this is the best and unique school for kids of today's generation..
    They not only teach academics, but also teach ethics and moral values to students..
    
    The director of the school (Pavani ma'am) will treat each and every student as her own child..
    Teachers are also very friendly with students..`,
  },
  {
    id: 4,
    name: "Ajay Prabha",
    star: StarIcon,
    feedback: `Dear all are you looking for a place where your child gets overall learning with moral values Jagadeeswari School of Learning is the place where can join without any doubt. And management encouraging challenging children by providing inclusive setup. Thank you Dr. Pavani Byreeka who is comforting child in all aspects by allowing kid calling as 'ATTA'`,
  },
  {
    id: 5,
    name: "Purushotham Vishwakarma",
    star: StarIcon,
    feedback: `Suparb...this is the best school for kids..... really exlent`,
  },
]

export default FeedbackList
