import rama from "./Assets/Y_Rama.jpg"
import akhila from "./Assets/TV_Akhila.jpg"
import nirupama from "./Assets/NF_Nirupama.jpg"
import rajitha from "./Assets/S_Rajitha.jpg"
import vishala from "./Assets/R_Vishala.jpg"
import eswari from "./Assets/Eswari.jpg"
import sravanthi from "./Assets/Sravanthi.jpg"
import renuka from "./Assets/G_Renuka.jpg"
import vedavathi from "./Assets/K_Vedavathi.jpg"
import vishalakshi from "./Assets/Vishalakshi.jpg"
import saritha from "./Assets/D_Saritha.jpg"
import suma from "./Assets/V_Suma.jpg"
import srujana from "./Assets/Srujana.jpg"

const FacilitatorsList = [
  {
    id: "1",
    image: rama,
    name: "Mrs. Yeturi Rama Srinivas",
    education: "B.A., P.P.T.T.C.",
    description: "30 years in Education domain",
    show: false,
  },
  {
    id: "2",
    image: akhila,
    name: "Mrs. Tirumala Vinjamuru Akhila",
    education: "B.Com., P.P.T.T.C.",
    description: "22 years in Education domain",
    show: false,
  },
  {
    id: "3",
    image: nirupama,
    name: "Mrs. N. F. Nirupama James",
    education: "M. A.(English), B.Ed., T.E.T.",
    description: "20 years in Education domain",
    show: false,
  },
  {
    id: "4",
    image: rajitha,
    name: "Mrs. Sama Rajitha Lakshmi",
    education: "B.Sc.(Computer Science),",
    description: "M.B.A.(H.R.)",
    show: false,
  },
  {
    id: "5",
    image: vishala,
    name: "Mrs. Rupanagudi Vishala",
    education: "B. Tech. (I.T.)",
    description: "11 years Experience - as Maths teacher",
    show: false,
  },
  {
    id: "6",
    image: eswari,
    name: "Mrs. Jonnalagadda N.M.Eswari",
    education: "B.Sc.,B.Ed.(Maths)",
    description: "",
    show: false,
  },
  {
    id: "7",
    image: sravanthi,
    name: "Mrs. Alli Sravanthi",
    education: "B.S.C.(B.Z.C.)",
    description: "",
    show: false,
  },
  {
    id: "8",
    image: renuka,
    name: "Mrs. Renuka Ghawalkar",
    education: "B.A., H.P.T - Hindi Pundit",
    description: "Training",
    show: false,
  },
  {
    id: "9",
    image: vedavathi,
    name: "Mrs. K. Vedavathi",
    education: "B.A., T.P.T. - Telugu Pundit",
    description: "Training",
    show: false,
  },
  {
    id: "10",
    image: vishalakshi,
    name: "Mrs. Vishalakshi",
    education: "B.Com., H.P.T - Hindi Pundit",
    description: "Training",
    show: false,
  },
  {
    id: "11",
    image: suma,
    name: "Mrs. Varnakavi Suma Latha",
    education: "BA ( Economics)",
    description: "",
    show: false,
  },
  {
    id: "12",
    image: saritha,
    name: "Mrs. Dandala Saritha",
    education: "B.Com.,",
    description: "MS Office, Type writing Higher",
    show: false,
  },
  {
    id: "13",
    image: srujana,
    name: "Dheeravath Srujana Naik",
    education: "BBA LL.B. Black Belt DAN-1",
    description:
      "INTERNATIONAL Gold Medalist,South Zone National Gold Medalist.Class 3 Referee Represented Telangana State and Medchal Malkajgiri district in several tournaments.",
    show: false,
  },
]
export default FacilitatorsList
