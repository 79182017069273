import others1 from "./Assets/others-1.jpg"
import others2 from "./Assets/others-2.jpg"
import others3 from "./Assets/others-3.jpg"
import others4 from "./Assets/others-4.jpg"

import image1 from "./Assets/2013-2014-0.jpg"
import image2 from "./Assets/2014-2015-0.jpg"
import image3 from "./Assets/2015-2016-0.jpg"
import image4 from "./Assets/2016-2017-0.jpg"
import image5 from "./Assets/2016-2017-1.jpg"
import image6 from "./Assets/2017-2018-0.jpg"
import image7 from "./Assets/2017-2018-1.jpg"
import image8 from "./Assets/2018-2019-0.jpg"
import image9 from "./Assets/2018-2019-1.jpg"
import image10 from "./Assets/2018-2019-2.jpg"
import image11 from "./Assets/2018-2019-3.jpg"
import image12 from "./Assets/2019-2020-0.jpg"
import image13 from "./Assets/2019-2020-1.jpg"
import image14 from "./Assets/2019-2020-2.jpg"

const others = [
  {
    id: "1",
    title: "Award Ceremonies Facebook Album pictures",
    image: others1,
  },
  {
    id: "2",
    title: "Award Ceremonies Facebook Album pictures",
    image: others2,
  },
  {
    id: "3",
    title: "Award Ceremonies Facebook Album pictures",
    image: others3,
  },
  {
    id: "4",
    title: "Award Ceremonies Facebook Album pictures",
    image: others4,
  },
]

const awards1 = [
  {
    id: "1",
    title: "2013-2014",
    image: image1,
  },
]
const awards2 = [
  {
    id: "1",
    title: "2014-2015",
    image: image2,
  },
]
const awards3 = [
  {
    id: "1",
    title: "2015-2016",
    image: image3,
  },
]
const awards4 = [
  {
    id: "1",
    title: "2016-2017",
    image: image4,
  },
  {
    id: "2",
    title: "2016-2017",
    image: image5,
  },
]
const awards5 = [
  {
    id: "1",
    title: "2017-2018",
    image: image6,
  },
  {
    id: "2",
    title: "2017-2018",
    image: image7,
  },
]
const awards6 = [
  {
    id: "1",
    title: "2018-2019",
    image: image8,
  },
  {
    id: "2",
    title: "2018-2019",
    image: image9,
  },
  {
    id: "3",
    title: "2018-2019",
    image: image10,
  },
  {
    id: "4",
    title: "2018-2019",
    image: image11,
  },
]
const awards7 = [
  {
    id: "1",
    title: "2019-2020",
    image: image12,
  },
  {
    id: "2",
    title: "2019-2020",
    image: image13,
  },
  {
    id: "3",
    title: "2019-2020",
    image: image14,
  },
]

export { others, awards1, awards2, awards3, awards4, awards5, awards6, awards7 }
