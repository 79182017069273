import Button from "react-bootstrap/Button"
import Container from "react-bootstrap/Container"
import Form from "react-bootstrap/Form"
import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar"
import NavDropdown from "react-bootstrap/NavDropdown"
import "bootstrap/dist/css/bootstrap.min.css"
import Dropdown from "react-bootstrap/Dropdown"
import { Outlet, useNavigate } from "react-router-dom"

import colors from "../../Colors"
import styled from "styled-components"
import logo from "./Assets/logo.png"

const NavbarContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const SchoolLogo = styled.img`
  width: 200px;
  height: 70px;
  object-fit: contain;
  margin: 10px;
`

function NavBarComp() {
  const Navigate = useNavigate()

  return (
    <NavbarContainer id="HOME">
      <SchoolLogo src={logo} alt="logo-pic" />
      <div style={{ width: "80%", position: "relative" }}>
        <Navbar bg="transparant" expand="lg">
          <Container fluid>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
              <Nav
                className="me-auto my-2 my-lg-0"
                style={{ maxHeight: "100px" }}
                navbarScroll
              >
                <Nav.Link onClick={() => Navigate("/")}>Home</Nav.Link>
                <NavDropdown title="About us" id="navbarScrollingDropdown">
                  <Nav.Link onClick={() => Navigate("aboutus")}>
                    About us
                  </Nav.Link>
                  <Nav.Link onClick={() => Navigate("chairmanmessage")}>
                    Chairman's Message
                  </Nav.Link>
                  <Nav.Link onClick={() => Navigate("directormessage")}>
                    Director's Message
                  </Nav.Link>
                  <Nav.Link onClick={() => Navigate("leadership")}>
                    Leadership
                  </Nav.Link>
                  <Nav.Link onClick={() => Navigate("facilitators")}>
                    Facilitators
                  </Nav.Link>
                </NavDropdown>
                <Nav.Link onClick={() => Navigate("jslschool")}>
                  JSL School
                </Nav.Link>
                <Nav.Link onClick={() => Navigate("daycare")}>
                  Day Care
                </Nav.Link>
                <Nav.Link onClick={() => Navigate("awards")}>Awards</Nav.Link>
                <NavDropdown title="Gallery" id="navbarScrollingDropdown">
                  <NavDropdown.Item onClick={() => Navigate("photos")}>
                    Photos
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={() => Navigate("videos")}>
                    Videos
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="Testimonials" id="navbarScrollingDropdown">
                  <NavDropdown.Item
                    target="_blank"
                    href="https://www.google.co.in/search?q=Kothapet+Edify+School,+House+No:-1-+5,+153/3,+Rd+Number+12F,+New+Maruthi+Nagar,+Kothapet,+Hyderabad,+Telangana+500035&ludocid=11055734768081119333"
                  >
                    Google Reviews
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={() => Navigate("parentsfeedback")}>
                    Parent's Feedback
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => Navigate("testimonialvideos")}
                  >
                    Videos
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link onClick={() => Navigate("contactus")}>
                  Contact us
                </Nav.Link>
              </Nav>
              <Dropdown className="login-dropdown">
                <Dropdown.Toggle
                  className="login-btn"
                  variant="warning"
                  id="dropdown-basic"
                >
                  Login
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-2">
                    Teacher's Login
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-3">
                    Parent's Login
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-2">Admin's Login</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      <Outlet />
    </NavbarContainer>
  )
}

export default NavBarComp
