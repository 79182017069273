import React from "react"
import colors from "../Colors"
import styled from "styled-components"

const TitleText = styled.p`
  font-size: 2rem;
  font-weight: 700;
  margin: ${(props) => (props.width ? "10px auto" : "10px 0px")};
  color: ${colors.orange};
  text-transform: uppercase;
`

function AppRouteTitle({ titleText, width }) {
  return (
    <TitleText width style={{ width: width }}>
      {titleText}
    </TitleText>
  )
}

export default AppRouteTitle
