import React from "react"
import styled from "styled-components"
import colors from "../../Colors"
import AppRouteTitle from "../../ReusableComponents/AppRouteTitle"
import AppRouteSubTitle from "../../ReusableComponents/AppRouteSubTitle"

import schoolImage from "./Assets/19.jpg"
import YouTube from "react-youtube"

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin 30px auto;

`

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
`

const Image = styled.img`
  width: 100%;
  height: 70vh;
  object-fit: cover;
`

const ContentContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin 20px auto;

`
const VideoContainer = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  place-items: center;
  margin: 30px auto;
  iframe {
    width: 700px;
    height: 360px;
  }
`

const opts = {
  height: "1200",
  width: "400",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
  },
}

const ready = (event) => {
  event.target.pauseVideo()
}

function Videos() {
  return (
    <Container>
      <ImageContainer>
        <Image src={schoolImage} alt="classroom" />
      </ImageContainer>
      <ContentContainer>
        <AppRouteTitle titleText="Videos" />
        <AppRouteSubTitle titleText="Videos of our children during events" />
      </ContentContainer>
      <VideoContainer>
        <YouTube
          videoId="ezoeaDNecd8"
          opts={opts}
          onReady={(event) => ready(event)}
        />
        <AppRouteSubTitle titleText="2016 Aug 15th Celebrations" />
      </VideoContainer>
      <VideoContainer>
        <AppRouteSubTitle titleText="Xylopia 2019 by Teachers" />
        <YouTube
          videoId="c-zG4bHtrU4"
          opts={opts}
          onReady={(event) => ready(event)}
        />
      </VideoContainer>
      <VideoContainer>
        <YouTube
          videoId="oB--fWw_MdU"
          opts={opts}
          onReady={(event) => ready(event)}
        />
        <AppRouteSubTitle titleText="Xylopia 2019 Dance by junior students" />
      </VideoContainer>
      <VideoContainer>
        <AppRouteSubTitle titleText="Xylopia 2019 Baahubali song skit by students" />
        <YouTube
          videoId="d6XE_BX2KfU"
          opts={opts}
          onReady={(event) => ready(event)}
        />
      </VideoContainer>
    </Container>
  )
}

export default Videos
