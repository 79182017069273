import React from "react"
import styled from "styled-components"
import colors from "../../Colors"
import AppRouteTitle from "../../ReusableComponents/AppRouteTitle"

import schoolImage from "./Assets/banner9.jpg"
import director1 from "./Assets/director_profile.jpg"
import director2 from "./Assets/director_profile2.jpg"
import AppRouteSubTitle from "../../ReusableComponents/AppRouteSubTitle"

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`
const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  margin: 30px auto;
`

const Image = styled.img`
  width: 100%;
  height: 70vh;
  object-fit: cover;
`
const MainContent = styled.div`
  margin: 30px auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
`
const MainContent2 = styled.div`
  margin: 0px auto;
  padding: 30px 0px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  background-color: ${colors.lightOrange};
`
const ChairmanImageContainer = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Chairmanimage = styled.img`
  width: 200px;
  height: 200px;
  border-radius: 100px;
  object-fit: cover;
  margin-top: 50px;
`
const ChairmanName = styled.p`
  font-size: 1.2rem;
  font-weight: 500;
  color: ${colors.orange};
`
const ContentContainer = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
  margin: auto;
`

const Paragraph = styled.p`
  font-size: 1.1rem;
  color: ${colors.mediumDark};
  font-weight: 500;
`
function DirectorMessage() {
  return (
    <Container>
      <ImageContainer>
        <Image src={schoolImage} alt="classroom" />
      </ImageContainer>
      <div style={{ marginLeft: "50px" }}>
        <AppRouteTitle titleText="Director's Message" />
      </div>
      <MainContent>
        <ChairmanImageContainer>
          <Chairmanimage src={director1} alt="chairman-picture" />
          <ChairmanName>- Dr. Pavani Byreeka</ChairmanName>
        </ChairmanImageContainer>
        <ContentContainer>
          <AppRouteSubTitle titleText="It’s Gonna Be Okay" />
          <Paragraph>
            Dear all parents, who are having school aged children 😊
          </Paragraph>
          <Paragraph>
            I am expressing thru this letter are whatever I just got in my mind.
            You might be inclined to create a minute by minute schedule for
            kids. You may have high hopes of hours of learning, including online
            activities, science experiments, and book reports. But our kids are
            just as scared as we are right now. Our kids not only can hear
            everything that is going on around them, but they feel our constant
            tension and anxiety. They have never experienced anything like this
            before. Although the idea of being off of school for weeks sounds
            awesome, they are probably picturing a fun time like summer break,
            not the reality of being trapped at home and not seeing their
            friends.
          </Paragraph>
          <Paragraph>
            Over the coming weeks, we will see an increase in behavior issues
            with our kids. Whether it is anxiety, or anger, or protest that they
            can’t do things normally – it will happen. We will see more
            meltdowns, tantrums and oppositional behavior in the coming weeks.
            This is normal and expected under these circumstances.
          </Paragraph>
          <Paragraph>
            Our kids need right now is to feel comforted and loved. To feel like
            it’s all going to be ok. And that might mean that we should tear up
            our perfect schedule and love on our kids a bit more. I am strongly
            encouraging playing games, baking, painting, snuggling under a
            blanket to watch movies or completing science experiments together.
            Start a book and read together as a family.
          </Paragraph>
          <Paragraph>
            Don’t worry about them regressing in school. Every single kid is in
            this boat and they all will be ok. When we are back in the
            classroom, we will all course correct and meet them where they are.
            Teachers are experts at this! Don’t pick fights with kids because
            they don’t want to do math. Don’t scream at kids for not following
            the schedule. Don’t mandate two hours of learning time if they are
            resisting it. We should avoid yelling at children if they resist
            completing school tasks at home after long periods of time.
          </Paragraph>
          <Paragraph>
            At the end of all of this, want to conclude with most signifiacant
            advice of prioritising the child’s mental health over their academic
            skills. And how they felt during this time will stay with them long
            after the memory of what they did during those weeks is long gone.
            So keep that in mind, ‘every single day’.
          </Paragraph>
          <Paragraph>
            WE DON’T NEED TO KNOW THE WHOLE ALPHABET OF SAFETY. THE a,b,c OF IT
            WILL SAVE US, IF WE FOLLOW IT: ALWAYS BE CAREFUL.👍✌ 💐
          </Paragraph>
        </ContentContainer>
      </MainContent>
      <MainContent2>
        <ChairmanImageContainer>
          <Chairmanimage src={director2} alt="chairman-picture" />
          <ChairmanName>- Dr. Pavani Byreeka</ChairmanName>
        </ChairmanImageContainer>
        <ContentContainer>
          <AppRouteSubTitle titleText="Teach Kids About Dignity Of Labour" />
          <Paragraph>
            A month ago I posted one question for poll survey - ' the importance
            of teaching kid about dignity of labour' . only four parents
            accepted for that.
          </Paragraph>
          <Paragraph>
            Ofcourse, we all want our children to grow into successful,
            confident and happy individuals -- we want them to have the best of
            everything. But we need to remember that teaching them good values
            and fostering gratitude in them for what they have, is also a big
            part of parenting.
          </Paragraph>
          <Paragraph>
            I could recollect my childhood days, there were a few people who
            were an integral part of our household, the cook, a wise old lady
            and the gardener. These were people who were obviously not part of
            the family, but were almost so. Even in our worst of moods, we
            couldn’t dream of being rude to them; for the codes of conduct were
            closely monitored by the adults.
          </Paragraph>
          <Paragraph>
            As time moved on, we got busier and wealthier. Our families became
            smaller and smaller, with expenses towards luxury going higher every
            moment. Larger homes, in posher colonies, with larger cars in the
            porch, better schools, international travel, gourmet meals and what
            not. At the same time, however, it is important to teach them to
            value their privileged lifestyle, as well as respect those who lead
            more modest ones -- they should be taught not to look down upon
            people who have less than they do, nor demean them in any way. We
            should educate our children about the dignity of labour.
          </Paragraph>
          <Paragraph>
            Our dependencies on what we call ‘domestic help’ have increased, and
            we have surrounded ourselves with people from lesser privileged
            backgrounds to help make our lives easier. But let us, for a moment
            look at it through the eyes of impressionable young minds; minds of
            our young children.
          </Paragraph>
          <Paragraph>
            Recently I was confronted by a scene one day, where my fourteen-year
            old Niece hands on hips, was looking at the painting she had put out
            to dry, and say in an accusatory tone, “Bhagya Aunty, why did you
            touch my painting? Do you even know how difficult it is to paint
            this thing?” The culprit, however, was a ten-year old,Nephew lurking
            in the background.
          </Paragraph>
          <Paragraph>
            The kids have seen me give away clothes and toys that they have
            outgrown, to the domestic help at home. One day, I got the jolt of
            my life when a too-smart-for-his-own-good ten-year old tells me,
            “This toy is broken – give it to Bhagya Aunty!” ………
          </Paragraph>
          <Paragraph>
            A defiant fourteen-year old had once exclaimed, “Cheeeee!! Why
            should I mop the floor? That’s what Bhagya Aunty is for!” when I
            asked her to clean up the mess she had created.
          </Paragraph>
          <Paragraph>
            This most certainly was not the message we wanted to teach them!
          </Paragraph>
          <Paragraph>
            Many other incidents, over the last few years of the kids growing
            up, have reflected to us how our behavior has influenced their
            attitude towards dignity of labour.
          </Paragraph>
          <Paragraph>
            I have started a few measures to counter the attitudes they seem to
            have developed. Same sharing with you.
          </Paragraph>
          <Paragraph>
            When it comes to daily routines as a responsible parent, you need to
            teach them that maidservants are not to be ordered around -- they
            are to be spoken to politely and respected. you can tell them carry
            their own plates into the kitchen after a meal, have them put washed
            and ironed clothes back into their closets, and get them to put
            their toys away after playtime sessions. In the process, they will
            earn respect for those expected to perform these tasks for them,
            responsibility and independence. At the same time, when your house
            staff is on leave, it will become easier for the children to manage
            things and you will have a helping hand.
          </Paragraph>
          <Paragraph>
            Children should be made aware of all those who make life easier for
            them , and if everyone refused menial jobs, we'd have to start doing
            them ourselves.
          </Paragraph>
          <Paragraph>
            Impress upon our kids that an honest living is a respectable living,
            whether it is made in a plush office or by the roadside. They need
            to be appreciative of hard work and of those who play a role in
            society. Somebody once told me that a child refused to speak in
            Hindi because it was the 'servants' language'! Obviously, the
            youngster must have heard an adult say something to that effect.
            Children tend to discuss their parents' occupations and are
            sometimes ashamed of them because of what friends may say.
          </Paragraph>
          <Paragraph>
            It's a wonderful thing to teach children charity -- But make sure
            that they don't consider those at the receiving end to be below
            them. Take them for a visit to an orphanage and take some gifts for
            the underprivileged. Teach kids to donate a small amount each month
            from their pocket money, and from their salaries when they grow up.
            Allow them to decide how much money to set aside and which needy
            person or institution they would like to help and in what manner.
            This will help them develop compassion, but it can also be a lesson
            in equality -- one who has less than you deserves your kindness and
            goodwill, but is not less important than you.
          </Paragraph>
          <Paragraph>
            My Dears!! Whatever you want your children to learn and whatever you
            say, always remember that YOUR ACTIONS SPEAK LOUDER THAN YOUR
            WORDS.. They are watching you and learning from you all the time. So
            the best way to teach them anything is to model them yourself. You
            are your children's best teacher and teaching them respect for
            others may be the best gift you ever give them...
          </Paragraph>
          ARE YOU WITH ME 🤚👍☺️..........
        </ContentContainer>
      </MainContent2>
    </Container>
  )
}

export default DirectorMessage
