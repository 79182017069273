import React from "react"
import styled from "styled-components"
import colors from "../../Colors"
import AppRouteTitle from "../../ReusableComponents/AppRouteTitle"
import AppRouteSubTitle from "../../ReusableComponents/AppRouteSubTitle"

import schoolImage from "./Assets/LowerSchool_3.jpg"

import { RiFootprintFill } from "react-icons/ri"
import { IoMdRose } from "react-icons/io"
import { NavItem } from "react-bootstrap"

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin 30px auto;

`

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
`

const Image = styled.img`
  width: 100%;
  height: 70vh;
  object-fit: cover;
`

const ContentColouredContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 30px;
  background-color: ${colors.lightOrange};

  ul {
    width: 80%;
    margin: 0px auto;
  }
  ul li {
    font-weight: 500;
    margin: 5px auto;
    text-align: left;
  }
`
const ContentUncolouredContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 30px;
  ul {
    width: 80%;
    margin: 0px auto;
  }
  ul li {
    font-weight: 500;
    margin: 5px auto;
    text-align: left;
  }
`

const Paragraph = styled.p`
  width: 80%;
  color: ${colors.mediumDark};
  font-size: 1.1rem;
  font-weight: 500;
  margin: 10px auto;
  text-align: left;
`
const PointsContainer = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  margin: 5px auto;
  p {
    width: 90%;
    margin: 5px;
    color: ${colors.mediumDark};
    font-size: 1.1rem;
    font-weight: 600;
    text-align: left;
  }
`
const Subheading = styled.p`
  width: 80%;

  color: ${colors.mediumDark};
  font-size: 1.1rem;
  font-weight: 600;
  margin: 5px auto;
  text-align: left;
`
const SubPoint = styled.p`
  width: 80%;
  color: ${colors.mediumDark};
  padding-left: 20px;
  font-size: 1rem;
  font-weight: 500;
  margin: 5px auto;
  text-align: left;
`

const FinalNote = styled.div`
  width: 80%;
  margin: 40px auto;
  background-color: ${colors.lightOrange};
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`
const FinalNoteText = styled.p`
  border-top: 1px solid ${colors.orange};
  width: 100%;
  font-size: 1.1rem;
  font-weight: 500;
  color: ${colors.black};
  text-align: center;
`
const Points = ({ text }) => {
  return (
    <PointsContainer>
      <RiFootprintFill size={20} color={colors.yellow} />
      <p>{text}</p>
    </PointsContainer>
  )
}
const MainPoints = ({ text }) => {
  return (
    <PointsContainer>
      <IoMdRose size={20} color={colors.yellow} />
      <p>{text}</p>
    </PointsContainer>
  )
}
function DayCare() {
  return (
    <Container>
      <ImageContainer>
        <Image src={schoolImage} alt="classroom" />
      </ImageContainer>
      <ContentUncolouredContainer>
        <Paragraph>
          The time has come: you’re ready to find a child care centre. Whether
          you’re going back to work or just looking for more support during the
          day, it’s important to find the right partner. You don’t want to trust
          the most precious member of your family to just anyone. But what
          should you look for? You know you want a high quality centre, but how
          do you know if you’ve found it?
        </Paragraph>
        <Paragraph>
          We have got you covered. This simple, five-step check-list will help
          you find the right preschool program for your child. Did you feel
          listened to and understood? Was the centre warm and welcoming? Did it
          feel like the right place for your child to grow and thrive? If you’re
          not sure, go back for a second visit! The right centre will want you
          to have everything you need to make the right decision.
        </Paragraph>
        <Points text="Step #1. Write your child care wish list" />
        <Points text="Step #2. Keep an eye out for safety" />
        <Points text="Step #3. Get on their level—literally!" />
        <Points text="Step #4. Look for teachers who love what they do" />
        <Points text="Step #5. Go with your gut (and your heart!)" />
      </ContentUncolouredContainer>
      <ContentColouredContainer>
        <AppRouteTitle
          width="80%"
          titleText="Our Preschool and Long Day Care Centre’s top-notch, state of the art Facilities and Amenities"
        />
        <MainPoints text="Kindergarten classroom and day care areas at Jagadeeswari School of Learning are equipped with fully air-conditioned rooms decorated with brightly painted walls." />
        <MainPoints text="The flooring, furniture and décor are eye-catching and colourful, providing a welcoming, inclusive and stimulating environment for every child. We believe it is important to amplify colour and imagery to encourage the development of skills such as creativity, role play, problem solving capabilities and critical thinking." />
        <MainPoints text="Our outdoor play space encourages sensory learning, allowing children to sharpen their senses as they explore the outside environment." />
        <MainPoints text="Aside from a playground for kids, every location features beautiful gardens that the children and staff have worked hard to develop. Together, we plant vegetables, fruits, herbs and flowers and water all the plants. Potted plants are spread across the indoor and outdoor areas." />
        <MainPoints text="Security at our centre is well-designed. High fences surround the perimeter of our outside play area." />
      </ContentColouredContainer>
      <ContentUncolouredContainer>
        <AppRouteTitle
          width="80%"
          titleText="Why choose Jagadeeswari School of Learning day care ?"
        />
        <Subheading>Qualified and Caring Staff</Subheading>
        <SubPoint>
          It is staffed by professional, qualified and experienced Early
          Childhood Educators.
        </SubPoint>
        <Subheading>Engaging Environment</Subheading>
        <SubPoint>
          Our preschool, long day care and childcare centre are fit-out with
          eye-catching and colourful décor, providing a welcoming, inclusive and
          stimulating environment for your child.
        </SubPoint>
        <Subheading>Outdoor Environment</Subheading>
        <SubPoint>
          Our outdoor environment and ambience are very natural and earthy, with
          real plants, trees and gardens.
        </SubPoint>
        <Subheading>Public Holidays</Subheading>
        <SubPoint>
          Unlike other centres, we don’t charge you for public holidays.
        </SubPoint>
        <Subheading>Qualified and Caring Staff</Subheading>
        <ul>
          <li>Caregivers wash hands after each diaper change</li>
          <li>
            Feeding utensils and Bottles are prepared under sanitary conditions
          </li>
          <li>Wash cloths shouldn't be shared</li>
          <li>Toys are rinsed off with a sanitizing solution daily.</li>
        </ul>
      </ContentUncolouredContainer>
      <ContentColouredContainer>
        <AppRouteTitle width="80%" titleText="Safety Measures" />
        <Subheading>
          We provide a safe environment for kids by taking the same safety
          precautions you do at home. There would be:
        </Subheading>
        <ul>
          <li>
            No choking hazards, including small toys or play things that can
            break apart into small pieces
          </li>
          <li>
            No pillows or fluffy bedding in cribs; babies should be put to sleep
            on their backs
          </li>
          <li>Gates on open stairways</li>
          <li>Spic-and-span bathroom</li>
          <li>An enclosed, secure outdoor space for play</li>
          <li>Clear floors (i.e., not littered with toys)</li>
          <li>Smoke detectors, clearly marked exits and fire extinguishers</li>
        </ul>
      </ContentColouredContainer>
      <ContentUncolouredContainer>
        <AppRouteTitle width="80%" titleText="A stimulating environment" />
        <Paragraph>
          You can find lots of verbal and physical interaction between kids and
          caregivers. Staff get down on the floor and interact with kids. And
          engage kids.
        </Paragraph>
        <Paragraph>
          We provide age-appropriate toys that are in good shape. We send email
          updates to parents, of the daily activities, which include lots of
          singing, talking, reading and dancing as well as on-the-floor playtime
          activities.
        </Paragraph>
      </ContentUncolouredContainer>
      <ContentColouredContainer>
        <AppRouteTitle width="80%" titleText="Separation of age groups " />
        <Paragraph>
          Babies under 18 months wouldn't be mingling with toddlers and older
          children — bigger tots can be pretty exuberant and haven't yet
          mastered being gentle with infants.
        </Paragraph>
      </ContentColouredContainer>
      <ContentUncolouredContainer>
        <AppRouteTitle width="80%" titleText="Happy children and staff" />
        <Paragraph>
          You can see, clean babies in spacious rooms, with a quiet area where
          they can nap in separate beds (and according to their own schedules).
          Our Caregivers are energetic, patient and genuinely interested in the
          kids. When you visit towards the end of the day, you will get more
          accurate picture of what the centre is like than you would first thing
          in the morning.
        </Paragraph>
      </ContentUncolouredContainer>
      <ContentColouredContainer>
        <AppRouteTitle
          width="80%"
          titleText="As always, we are putting safety first to protect our families and staff. "
        />
        <Paragraph>
          You can see our Health & Safety measures in action! You will notice at
          our centre:
        </Paragraph>
        <ul>
          <li>
            Handwashing and sanitizing. Everyone who enters the building will
            need to wash hands, and we’ll continue to integrate handwashing into
            the curriculum. Hand sanitizer will be available throughout the
            centre.
          </li>
          <li>
            Stay home if sick. If you or your child has been sick, we ask that
            the ill person stay home until they have been symptom-free without
            medication for at least 72 hours. Classrooms will be open to healthy
            individuals only.
          </li>
          <li>
            Sick space. centres will have a dedicated space (in or out of the
            classroom) where teachers can care for sick children while being
            separated from others until a family member can come to pick up.{" "}
          </li>
          <li>
            Same person drop-off and pick-up. Families are asked to assign one
            or two family member(s) for drop off and pick up to minimize
            exposure to multiple people. If this is not possible, we ask parents
            to get pick-up card to those members other than parent to drop/pick
            children at the centre.
          </li>
          <li>
            Restricted classroom access. To minimize potential exposure, we ask
            that families not walk your child to their classroom when you
            arrive. Instead, please check in with staff in the lobby, and staff
            will walk your child to their classroom. Classrooms will be reserved
            for teachers and children only.{" "}
          </li>
          <li>
            Physical distancing. We’ll continue to practice social distancing as
            much as possible to reduce risk. To minimize potential exposure,
            children will continue to keep distance from children and staff in
            other classrooms.{" "}
          </li>
          <li>
            Classroom cleanliness. Toys, playground equipment, and other
            high-touch surfaces will be regularly disinfected and rotated
            throughout the day. We will pause the use of sensory tables, play
            dough, large pillows or stuffed animals, and other materials that
            are difficult to clean.{" "}
          </li>
          <li>
            Modifications to family-style dining. We have modified our shared
            mealtime practices to reduce the spread of germs. Students will
            still eat together but will not be serving themselves.
          </li>
          <li>
            Playground restrictions. Outdoor play structures will be utilized by
            one class at a time, with increased time between play to allow for
            cleaning.
          </li>
          <li>
            Field trips and events. Trips and events could be cancelled or
            postponed until further notice.
          </li>
          <li>
            Health screening and temperature checks. Instead of families taking
            children’s temperatures at home, our staff may check temperatures
            on-site at check-in.
          </li>
          <li>
            Stay home if sick. Instead of 48 hours, we may require sick
            individuals to be symptom-free 72 hours before returning to the
            centre.
          </li>
        </ul>
        <MainPoints text="100% Secured" />
        <MainPoints text="Finger-jam preventers" />
        <MainPoints text="Flexible Hours" />
        <MainPoints text="Children Hand Railings" />
        <MainPoints text="Sound-proof Rooms" />
        <MainPoints text="Shuttle Transportation" />
      </ContentColouredContainer>
      <ContentUncolouredContainer>
        <AppRouteTitle width="80%" titleText="You would be glad to know:" />
        <Paragraph>
          It’s under management of a child psychologist and we promise
          high-quality day care environment which can improve the cognitive,
          vocabulary and social development of your child by providing safe and
          mentally stimulating spaces to explore, play and learn the rules of
          social interaction at a pace that meets each child’s individual needs,
        </Paragraph>
        <Paragraph>
          Our day care environment will support your child in learning how to
          form positive and trusting relationships, acquire balance and
          coordination, as well as how to manage stress, challenging situations
          and risks by providing indoor and outdoor areas where your child is
          able to take calculated risks and engage in realistic play experiences
          with their peers.
        </Paragraph>
        <Paragraph>We hope to meet you and your family soon!</Paragraph>
      </ContentUncolouredContainer>
      <FinalNote>
        <AppRouteSubTitle titleText="Our Facility’s Fun & Functional Features and Amenities" />
        <FinalNoteText>
          Everything in our facility was designed and built with children in
          mind. We wanted to make sure that the features facilitated the best
          and most educational environment possible for the children in our
          care. Whether it be the playing and learning areas, the staircase or
          the parking lot — we want you to know your children are safe and
          secure.
        </FinalNoteText>
      </FinalNote>
    </Container>
  )
}

export default DayCare
