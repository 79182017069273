const Colors = {
  white: "#ffffff",
  yellow: "#ecb745",
  orange: "#fe8367",
  black: "#060a1b",
  lightGrey: "#a3a3a3",
  lightWhite: "#ebecee",
  lightOrange: "#fff7f5",
  mediumOrange: "#fce5de",
  dark: "#2f3032",
  mediumDark: "#515256",
  lightWhite: "#eeeeee",
}

export default Colors
