const EventList = [
  {
    id: "1",
    date: "07",
    monthAndYear: "April, 2023",
    eventTitle: "Farewell Function",
    startTime: "09:00 Am",
    endTime: "03:00 Pm",
    location: "School Ground",
    description:
      "Farewell function for 10th standard students from 9th class students to show their respect and love on the day",
  },
  {
    id: "2",
    date: "27",
    monthAndYear: "March, 2023",
    eventTitle: "Farewell Function",
    startTime: "09:00 Am",
    endTime: "03:00 Pm",
    location: "School Ground",
    description:
      "Farewell function for 10th standard students from 9th class students to show their respect and love on the day",
  },
  {
    id: "3",
    date: "06",
    monthAndYear: "March, 2023",
    eventTitle: "Farewell Function",
    startTime: "09:00 Am",
    endTime: "03:00 Pm",
    location: "School Ground",
    description:
      "Farewell function for 10th standard students from 9th class students to show their respect and love on the day",
  },
  {
    id: "4",
    date: "27",
    monthAndYear: "February, 2023",
    eventTitle: "Farewell Function",
    startTime: "09:00 Am",
    endTime: "03:00 Pm",
    location: "School Ground",
    description:
      "Farewell function for 10th standard students from 9th class students to show their respect and love on the day",
  },
  {
    id: "25",
    date: "07",
    monthAndYear: "February, 2023",
    eventTitle: "Farewell Function",
    startTime: "09:00 Am",
    endTime: "03:00 Pm",
    location: "School Ground",
    description:
      "Farewell function for 10th standard students from 9th class students to show their respect and love on the day",
  },
  {
    id: "6",
    date: "20",
    monthAndYear: "February, 2023",
    eventTitle: "Farewell Function",
    startTime: "09:00 Am",
    endTime: "03:00 Pm",
    location: "School Ground",
    description:
      "Farewell function for 10th standard students from 9th class students to show their respect and love on the day",
  },
]

export default EventList
