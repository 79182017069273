import * as React from "react"
import ImageList from "@mui/material/ImageList"
import ImageListItem from "@mui/material/ImageListItem"
import image1 from "./Assets/1.jpg"
import image2 from "./Assets/2.jpg"
import image3 from "./Assets/3.jpg"
import image4 from "./Assets/4.jpg"
import image5 from "./Assets/5.jpg"

export default function ImageGallery() {
  return (
    <ImageList
      sx={{ width: "100%", height: "auto" }}
      variant="woven"
      cols={3}
      gap={8}
    >
      {itemData.map((item) => (
        <ImageListItem key={item.img}>
          <img
            src={`${item.img}?w=161&fit=crop&auto=format`}
            srcSet={`${item.img}?w=161&fit=crop&auto=format&dpr=2 2x`}
            alt={item.title}
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>
  )
}

const itemData = [
  {
    img: image1,
    title: "image1",
  },
  {
    img: image1,
    title: "IMage5",
  },
  {
    img: image1,
    title: "Image3",
  },
  {
    img: image1,
    title: "Image4",
  },
  {
    img: image1,
    title: "Image2",
  },
  {
    img: image1,
    title: "Image2",
  },
]
