import React from "react"
import styled from "styled-components"
import colors from "../../Colors"
import AppRouteTitle from "../../ReusableComponents/AppRouteTitle"
import AppRouteSubTitle from "../../ReusableComponents/AppRouteSubTitle"

import schoolImage from "./Assets/9.jpg"
import { BsFillStarFill } from "react-icons/bs"

import video1 from "./Assets/Testimonial1.mp4"
import video2 from "./Assets/Testimonial2.mp4"
import video3 from "./Assets/Testimonial3.mp4"
import video4 from "./Assets/Testimonial4.mp4"

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin 30px auto;

`

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
`

const Image = styled.img`
  width: 100%;
  height: 70vh;
  object-fit: cover;
`

const ContentContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin 20px auto;

`
const ContainerDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`
const VideoContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  place-items: center;
  margin: 30px auto;
  iframe {
    width: 700px;
    height: 360px;
  }
`

const ParentTitle = styled.p`
  font-size: 1.1rem;
  font-weight: 500;
  color: ${colors.mediumDark};
`

function TestimonialVideos() {
  return (
    <Container>
      <ImageContainer>
        <Image src={schoolImage} alt="classroom" />
      </ImageContainer>
      <ContentContainer>
        <AppRouteTitle titleText="Vedio Feedback" />
        <AppRouteSubTitle titleText="Feedback from the parents of our beloved students" />
      </ContentContainer>
      <ContainerDiv>
        <VideoContainer>
          <video src={video1} controls="controls" autoplay={false} />
          <ParentTitle>Parent</ParentTitle>
          <p>
            <span>
              <BsFillStarFill color="#eab845" size={20} />
            </span>
            <span>
              <BsFillStarFill color="#eab845" size={20} />
            </span>
            <span>
              <BsFillStarFill color="#eab845" size={20} />
            </span>
            <span>
              <BsFillStarFill color="#eab845" size={20} />
            </span>
            <span>
              <BsFillStarFill color="#eab845" size={20} />
            </span>
          </p>
        </VideoContainer>
        <VideoContainer>
          <video src={video2} controls="controls" autoplay={false} />
          <ParentTitle>Parent</ParentTitle>
          <p>
            <span>
              <BsFillStarFill color="#eab845" size={20} />
            </span>
            <span>
              <BsFillStarFill color="#eab845" size={20} />
            </span>
            <span>
              <BsFillStarFill color="#eab845" size={20} />
            </span>
            <span>
              <BsFillStarFill color="#eab845" size={20} />
            </span>
            <span>
              <BsFillStarFill color="#eab845" size={20} />
            </span>
          </p>
        </VideoContainer>
        <VideoContainer>
          <video src={video3} controls="controls" autoplay={false} />

          <ParentTitle>Parent</ParentTitle>
          <p>
            <span>
              <BsFillStarFill color="#eab845" size={20} />
            </span>
            <span>
              <BsFillStarFill color="#eab845" size={20} />
            </span>
            <span>
              <BsFillStarFill color="#eab845" size={20} />
            </span>
            <span>
              <BsFillStarFill color="#eab845" size={20} />
            </span>
            <span>
              <BsFillStarFill color="#eab845" size={20} />
            </span>
          </p>
        </VideoContainer>
      </ContainerDiv>
      <VideoContainer>
        <video src={video4} controls="controls" autoplay={false} />
        <ParentTitle>Parent</ParentTitle>
        <p>
          <span>
            <BsFillStarFill color="#eab845" size={20} />
          </span>
          <span>
            <BsFillStarFill color="#eab845" size={20} />
          </span>
          <span>
            <BsFillStarFill color="#eab845" size={20} />
          </span>
          <span>
            <BsFillStarFill color="#eab845" size={20} />
          </span>
          <span>
            <BsFillStarFill color="#eab845" size={20} />
          </span>
        </p>
      </VideoContainer>
    </Container>
  )
}

export default TestimonialVideos
