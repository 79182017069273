import React from "react"
import colors from "../../Colors"
import styled from "styled-components"

import { IoPersonCircle } from "react-icons/io5"
import { IoMdQuote } from "react-icons/io"

const CardContainer = styled.div`
  width: 100;
  background-color: ${colors.white};
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
  padding: 20px;
  // border-radius: 20px;
  position: relative;
`
const CardPerson = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`

// const PersonImage = styled.img`
//   width: 100px;
//   height: 100px;
//   border-radius: 50px;
//   border: 5px solid ${colors.orange};
// `

const QuotationMark = styled.div`
  position: absolute;
  top: 10px;
  right: 30px;
  opacity: 0.3;
`
const PersonDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`
const PersonName = styled.p`
  font-size: 1.2rem;
  line-height: 1rem;
  margin: 5px;
  font-weight: 600;
  color: ${colors.black};
  text-align: left;
`
const PersonProfession = styled.p`
  font-size: 0.8rem;
  line-height: 1rem;
  text-align: left;

  margin: 5px;
  color: ${colors.black};
  text-transform: uppercase;
`
const CardDescription = styled.div`
  width: 90%;
`
const CardDesc = styled.p`
  font-size: 0.9rem;
  color: ${colors.lightGrey};
  line-height: 1.3rem;
  text-align: left;
`

function TestimonialCard({ item }) {
  console.log(item)
  return (
    <CardContainer>
      <QuotationMark>
        <IoMdQuote size={80} color={colors.orange} />
      </QuotationMark>
      <CardPerson>
        <IoPersonCircle size={80} color={colors.orange} />
        <PersonDetails>
          <PersonName>{item.name}</PersonName>
          <PersonProfession>{item.profession}</PersonProfession>
        </PersonDetails>
      </CardPerson>
      <CardDescription>
        <CardDesc>{item.description}</CardDesc>
      </CardDescription>
    </CardContainer>
  )
}

export default TestimonialCard
