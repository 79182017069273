import React from "react"
import styled from "styled-components"
import colors from "../../Colors"
import AppRouteTitle from "../../ReusableComponents/AppRouteTitle"
import schoolImage from "./Assets/Yoga2.jpg"

import chairman from "./Assets/chairman.jpg"
import director from "./Assets/director01.jpg"
import sreedhar from "./Assets/sreedhar_profile.jpg"

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin 30px auto;

`
const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
`

const Image = styled.img`
  width: 100%;
  height: 70vh;
  object-fit: cover;
`

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin: 30px auto;
`
const ContentItem = styled.div`
  width: 90%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 20px auto;
`

const ProfileDetails = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`

const Content = styled.p`
  width: 80%;
  text-align: left;
  margin: 20px auto;
  font-size: 1.1rem;
  color: ${colors.mediumDark};
  font-weight: 500;
`
const ProfileImage = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 60px;
  border-width: 2px solid ${colors.orange};
  object-fit: cover;
  margin: 0px auto;
`
const ProfileName = styled.p`
  width: 100%;
  text-align: center;
  font-size: 1.2rem;
  color: ${colors.dark};
  font-weight: 600;
  line-height: 1rem;
  margin: 10px;
`
const ProfileProfession = styled.p`
  width: 100%;
  text-align: center;
  font-size: 1.1rem;
  color: ${colors.orange};
  font-weight: 700;
  margin: 10px;
  line-height: 1rem;
`
function Leadership() {
  return (
    <Container>
      <ImageContainer>
        <Image src={schoolImage} alt="classroom" />
      </ImageContainer>
      <ContentContainer>
        <ContentItem>
          <ProfileDetails>
            <ProfileImage src={chairman} alt="profile" />
            <ProfileName>Sri. J. Narsi Reddy</ProfileName>
            <ProfileProfession>Chairman</ProfileProfession>
          </ProfileDetails>
          <Content>
            He is a seasoned professional, retired in May 2020 as Scientific
            Advisor to Indian Railways with multiple awards of excellence. Has
            great passion for Education and Information Technology.He researched
            diverse teaching aids and methodologies including latest electronic
            gadgets to provide the highest experience of sound and vision for
            helping with mind mapping of various concepts taught to students.
          </Content>
        </ContentItem>
        <ContentItem>
          <ProfileDetails>
            <ProfileImage src={director} alt="profile" />
            <ProfileName>Dr. Pavani Byreeka</ProfileName>
            <ProfileProfession>Director</ProfileProfession>
          </ProfileDetails>
          <Content>
            She is topper in Bachelor of Education from Osmania University. A
            star performer on a research project on women rights and post
            graduate from NALSAR University. Well trained and multi-faceted
            talent in alternative teaching methodologies. She presented seminars
            in child psychology in India and UK. Well versed with creative
            activities for stimulating young brains. She leads from the front by
            mentoring staff and is always available to lend a helping hand to
            parents by sharing her wisdom. Participated in UNESCO – IBO and UK –
            Cambridge curriculum workshops.
          </Content>
        </ContentItem>
        <ContentItem>
          <ProfileDetails>
            <ProfileImage src={sreedhar} alt="profile" />
            <ProfileName>Sri. Sreedhar Byreeka</ProfileName>
            <ProfileProfession>Strategic Advisor</ProfileProfession>
          </ProfileDetails>
          <Content>
            Highly experienced IT Project and Implementation Manager with
            Fortune 100 multi-national companies in the US, Europe and Asia. He
            has trained NRI children in Vedic Mathematics and proficient in the
            traditional Indian teaching methodologies including Purna Vidya. He
            has great zeal to synergize the western and eastern knowledge
            management techniques and build efficient education delivery
            systems. He will drive many of the future enhancements to this
            website.
          </Content>
        </ContentItem>
      </ContentContainer>
    </Container>
  )
}

export default Leadership
