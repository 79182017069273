import React from "react"
import colors from "../Colors"
import styled from "styled-components"

const TitleText = styled.p`
  font-size: 1.5rem;
  font-weight: 700;
  margin: 10px 0px;
  color: ${colors.mediumDark};
`

function AppRouteSubTitle({ titleText }) {
  return <TitleText>{titleText}</TitleText>
}

export default AppRouteSubTitle
