import React from "react"
import colors from "../../Colors"
import styled from "styled-components"

import logo from "../Navbar/Assets/JSL-logo.png"
import { Link, useLocation } from "react-router-dom"
import { NavBarLinks, FooterQuickLinks } from "./NavBarLinks"

import { RiFacebookCircleFill } from "react-icons/ri"
import { AiFillYoutube } from "react-icons/ai"
import { RiWhatsappFill } from "react-icons/ri"
import { ImLocation } from "react-icons/im"
import { GrMail } from "react-icons/gr"
import { BsFillTelephoneFill } from "react-icons/bs"
import { IoTime } from "react-icons/io5"

import cbse from "./Assets/cbse-logo.png"
import nios from "./Assets/nios-logo.png"
import nischala from "./Assets/NischalaTechnologiesLogo.jpg"
import suman from "./Assets/sumantv-main-logo-1.png"

const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  background-color: ${colors.mediumDark};
`
const SchoolLogo = styled.img`
  width: 200px;
  height: 70px;
  object-fit: contain;
`
const NavBarContainer = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin: 20px auto;
`
const SocialMediaContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`
const NavBarLink = styled.p`
  color: ${colors.white};
  font-weight: 500;
  transition: 0.5s all ease;
  cursor: pointer;
  margin: 10px;
  &:hover {
    font-size: 1.05rem;
    font-weight: 600;
    color: ${colors.orange};
  }
  a {
    text-decoration: none;
    color: ${colors.white};
    &:hover {
      color: ${colors.orange};
    }
  }
`
const TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${colors.lightGrey};
  gap: 10px;
`
const BottomContainer = styled.div`
  width: 90%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 20px auto;
`
const SchoolBrief = styled.div`
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;

  p {
    color: ${colors.white};
    font-size: 0.8rem;
    font-weight: 500;
    margin: 10px 0px;
    line-height: 1.5rem;
  }
`
const QuickLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

const FooterItemsHeader = styled.p`
  font-size: 1.2rem;
  color: ${colors.white};
  font-weight: 600;
  text-transform: uppercase;
`
const AffliationsAndCollaborations = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
`

const Affliations = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
`
const LogoImage = styled.img`
  width: 100px;
  height: 70px;
  object-fit: contain;
`

const ContactUs = styled.div`
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

const ContactItem = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
  margin: 10px;
`

const ContactContent = styled.p`
  width: 250px;
  font-size: 0.9rem;
  color: ${colors.white};
  margin: 0px;
`

const FinalNote = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-top: 1px solid ${colors.lightGrey};
  margin: auto;

  p {
    font-size: 1.2rem;
    color: ${colors.orange};
    text-align: center;
    margin: 10px;
  }
`
function Footer() {
  const { pathname } = useLocation()
  return (
    <Container>
      <TopContainer>
        {pathname == "/" && (
          <NavBarContainer>
            {NavBarLinks.map((item) => (
              <NavBarLink>
                <a href={item.link}>{item.text}</a>
              </NavBarLink>
            ))}
          </NavBarContainer>
        )}
      </TopContainer>
      <BottomContainer>
        <SchoolBrief>
          <SchoolLogo src={logo} alt="school-logo" />
          <p>
            We at JSL ensure that each child is guided to give his/her very best
            to every pursuit he/she undertakes. It facilitates the most
            meaningful partnership between Parents and the School, to ensure
            that the children experience the best possible holistic and
            effective education.
          </p>
        </SchoolBrief>
        <QuickLinks>
          <FooterItemsHeader>Quick Links</FooterItemsHeader>
          {FooterQuickLinks.map((item) => (
            <NavBarLink>
              <Link to={item.link}>{item.text}</Link>
            </NavBarLink>
          ))}
        </QuickLinks>
        <AffliationsAndCollaborations>
          <FooterItemsHeader>Affliations</FooterItemsHeader>
          <Affliations>
            <LogoImage src={cbse} alt="logoimag" />
            <LogoImage src={nios} alt="logoimag" />
          </Affliations>
          <FooterItemsHeader>Partners / Collaborators</FooterItemsHeader>
          <Affliations>
            <LogoImage src={nischala} alt="logoimag" />
            <LogoImage src={suman} alt="logoimag" />
          </Affliations>
        </AffliationsAndCollaborations>
        <ContactUs>
          <FooterItemsHeader>Contact Details</FooterItemsHeader>

          <ContactItem>
            <ImLocation size={20} color={colors.white} />
            <ContactContent>
              New Maruthinagar, Road #12F, Kothapet, Dilsukhnagar Hyderabad -
              500060 Telangana
            </ContactContent>
          </ContactItem>
          <ContactItem>
            <GrMail size={20} color={colors.white} />
            <ContactContent>contactus@kothapetedifyschool.com</ContactContent>
          </ContactItem>
          <ContactItem>
            <BsFillTelephoneFill size={20} color={colors.white} />
            <ContactContent>+91-8790416232</ContactContent>
          </ContactItem>
          <ContactItem>
            <IoTime size={20} color={colors.white} />
            <ContactContent>9:00 am to 6:00 pm</ContactContent>
          </ContactItem>
        </ContactUs>
      </BottomContainer>
      <FinalNote>
        <p>Jagadeeshwari School of Learning © 2023</p>
        <SocialMediaContainer>
          <a
            href="https://www.facebook.com/pg/childpsycholozist"
            target="_blank"
          >
            <RiFacebookCircleFill size={30} color={colors.orange} />
          </a>
          <a
            href="https://www.youtube.com/channel/UCa0zpYGYBgn9IMdJ_gbriaw"
            target="_blank"
          >
            <AiFillYoutube size={30} color={colors.orange} />
          </a>
          <a
            href="https://api.whatsapp.com/send/?phone=918790416232&text&type=phone_number&app_absent=0"
            target="_blank"
          >
            <RiWhatsappFill size={30} color={colors.orange} />
          </a>
        </SocialMediaContainer>
      </FinalNote>
    </Container>
  )
}

export default Footer
