import image1 from "./Assets/ugadi_celebrations.jpg"
import image2 from "./Assets/Safety1.jpg"
import image3 from "./Assets/HealthCheck1.jpg"

const BlogsList = [
  {
    id: "1",
    image: image1,
    date: "02 April, 2022",
    description:
      "Ugadi Celebration at our school, we teachers prepared dishes to serve the children on the occasion",
  },
  {
    id: "2",
    image: image2,
    date: "28 April, 2022",
    description:
      "Safety day celebrations, we conducted an event to let children have awareness on safety",
  },
  {
    id: "3",
    image: image3,
    date: "30 May, 2022",
    description:
      "Medical health camp for our children, We conduct health camps every year",
  },
]

export default BlogsList
